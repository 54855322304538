import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/themify-icons.css";
import "./assets/css/font-awesome.css";
import "./assets/css/animate.min.css";
import "./assets/css/animsition.css";
import "./assets/css/flexslider.css";
import "./assets/css/style.css";
import "./assets/css/header.css";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/Home/Home";
import Navbar from "./components/Home/NavbarIndex";
import Footer from "./components/Home//FooterIndex";
import AppRoute from "./routes/route";
import { authProtectedRoutes } from "./routes/index";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useMemo } from "react";

function App() {
  const getProtectedRoutes = useMemo(() => {
    return authProtectedRoutes.map((route) => (
      <AppRoute
        path={route.path}
        component={route.component}
        isAuthProtected={true}
        pageId={route.pageId}
        exact={route.exact}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProtectedRoutes]);

  sessionStorage.setItem("token", "token");
  localStorage.setItem("token", "token");

  if (localStorage.getItem("token")) {
    return (
      <Router>
        <Navbar />
        <Suspense fallback={() => <div></div>}>
          <Switch>{getProtectedRoutes}</Switch>
        </Suspense>
        <Footer />
      </Router>
    );
  }
  return <Home />;
}

export default App;
