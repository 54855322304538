import { useSelector, useDispatch } from "react-redux";
import React from "react";
import { getTopVisitedStores } from "../../redux/stores/actions";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../utils";
import PlaceHolderImg from "../../assets/images/PlaceholderImage.png";

export default function MostTrending() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTopVisitedStores());
  }, [dispatch]);

  const addDefaultSrc = (e) => {
    e.target.src = PlaceHolderImg;
  };

  const { topRatedStores } = useSelector((state) => state.Stores);
  return (
    <section id="page" class="container" style={{ padding: "2% 0"}}>
      <h4 className="Recommended">Most Trending Stores</h4>
      <div className="row" style={{ padding: "0.5rem" }}>
        {topRatedStores &&
          topRatedStores.slice(0, 12).map((data, key) => (
            <div className="col-sm-2 " key={key}>
              <div
                className="featured-stores"
                style={{ backgroundColor: "white" }}
              >
                <Link to={`/store/${data.store_details.slug}`}>
                  <img
                    onError={(e) => addDefaultSrc(e)}
                    src={getImageUrl(data?.store_details.image, PlaceHolderImg)}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}
