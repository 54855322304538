import {
  GET_COUPON_BY_ID_FAILURE,
  GET_COUPON_BY_ID_SUCCESS,
  SET_COUPON_BY_ID_LOADING,
  RESET_COUPON_BY_ID,
  GET_COUPON_LIST_BY_WEBSITE_SUCCESS,
  GET_COUPON_LIST_BY_WEBSITE_FAILURE,
  GET_COUPON_LIST_BY_NAME_SUCCESS,
  GET_COUPON_LIST_BY_NAME_FAILURE,
  GET_ACTIVE_STORES_BASED_ON_DOMAIN_FAILURE,
  GET_ACTIVE_STORES_BASED_ON_DOMAIN_SUCCESS,
  GET_TEST_FAILURE,
  GET_TEST_SUCCESS,
  GET_END_USER_SUCCESS,
  GET_END_USER_FAILURE,
  RECOMMEND_BANNER_BY_DOMAIN_FAILURE,
  RECOMMEND_BANNER_BY_DOMAIN_SUCCESS
} from "./actionTypes";

const initialState = {
  couponByID:{},
  activeStoresList:[],
  couponListByWebsite:[],
  couponListByName:[],
  totalRecords:0,
  totalPages:0,
  error: null,
  errorMessage:'',
  success:false,
  successMessage:'',
  loading: false,
  test:[],
  search: {},
  getEndUser: [],
  getRecommendBanner: [],
};

const Coupon = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUPON_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
        error: false,
      };

    case RESET_COUPON_BY_ID:
      return {
        ...state,
        couponByID:{},
      };

    case GET_COUPON_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage:'',
        couponByID:action.payload,
       
      };
    case GET_COUPON_BY_ID_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        error: true,
        loading: false,
        couponByID:{}
      };
      case GET_COUPON_LIST_BY_WEBSITE_SUCCESS:
        return {
        ...state,
        loading: false,
        successMessage: "",
        couponListByName: action.payload,
        totalRecords: action.totalRecords,
        totalPages: action.total_pages,
        per_page: action.per_page,
        page: action.page,
        search: action.search,
         
        };
      case GET_COUPON_LIST_BY_WEBSITE_FAILURE:
        return {
          ...state,
          errorMessage: action.errorMessage,
          error: true,
          loading: false,
          couponListByWebsite:[]
        };
      case GET_COUPON_LIST_BY_NAME_SUCCESS:
        return {
          ...state,
          loading: false,
          successMessage:'',
          couponListByName:action.payload,
          totalRecords: action.totalRecords,
          totalPages: action.total_pages,
          per_page: action.per_page,
          page: action.page,
          search: action.search,
        };
      case GET_COUPON_LIST_BY_NAME_FAILURE:
        return {
          ...state,
          errorMessage: action.errorMessage,
          error: true,
          loading: false,
          couponListByWebsite:[]
        };
      case GET_ACTIVE_STORES_BASED_ON_DOMAIN_SUCCESS:
        return {
          ...state,
          loading: false,
          successMessage:'',
          activeStoresList:action.payload,
        };
      case GET_ACTIVE_STORES_BASED_ON_DOMAIN_FAILURE:
        return {
          ...state,
          errorMessage: action.errorMessage,
          error: true,
          loading: false,
          activeStoresList:[]
        };
      case GET_TEST_SUCCESS:
        return {
          ...state,
          loading: false,
          // successMessage:'',
          test:action.payload,
        };
      case GET_TEST_FAILURE:
        return {
          ...state,
          // errorMessage: action.errorMessage,
          // error: true,
          loading: false,
          test:[],
        };
    case GET_END_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        getEndUser: action.payload,
      };
    case GET_END_USER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
      case RECOMMEND_BANNER_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        getRecommendBanner: action.payload,
      };
    case RECOMMEND_BANNER_BY_DOMAIN_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default Coupon;
