import { all } from "redux-saga/effects";

import LoginSaga from "./auth/login/saga";
import RegisterSaga from "./auth/register/saga";
import CategorySaga from "./category/saga";
import ContactSaga from "./contact/saga";
import CouponBasedOnDomain from "./couponbaseondomain/saga";
import CouponUrlSaga from "./couponUrl/saga";
import StoresSaga from "./stores/saga";
import WebsiteSaga from "./website/saga";
import Couponsaga from "./coupons/saga";
import NewsletterSaga from "./Newsletter/saga";

export default function* rootSaga() {
  yield all([
    LoginSaga(),
    RegisterSaga(),
    CategorySaga(),
    CouponBasedOnDomain(),
    StoresSaga(),
    WebsiteSaga(),
    CouponUrlSaga(),
    ContactSaga(),
    Couponsaga(),
    NewsletterSaga()
  ]);
}
