import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { postNewsletteraction, resetNewsletterStateAction} from "../../../redux/Newsletter/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from "../../UI/CustomLoader";
import image from "../../../assets/images/newsl-h2.avif"

export default function NewsLetter() {

 
const { successMessage, loading, errorMessage } = useSelector(
    (state) => state.Newsletter
  );
   const dispatch = useDispatch();

  const [email_id, setEmail] = useState("");


const validate = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

  const handleNewsletter = () => {
  if(!(validate(email_id))){
    toast.error("Please provide a valid email address.")
    return;
  }
    dispatch(postNewsletteraction({ email_id }));

  };

React.useEffect(()=>{
   if(successMessage){
      toast.success(successMessage);
    dispatch(resetNewsletterStateAction());
    }

    if(errorMessage){
      toast.error(errorMessage);
    dispatch(resetNewsletterStateAction());
    }

  },[dispatch, errorMessage, loading, successMessage]) 



	return(
    <>

 <section className="wrappern" style={{padding: "2%"}}>
  <div className="containern">
    <div className="img__container">
      <img
        src={image}
        alt="salad"
        className="img"
      />
    </div>
    <div className="content">
      <h2 className="subtitle">Subscribe today</h2>
      <h1 className="title">Never miss an offer</h1>
      <input
        type="text"
        className="mail"
        placeholder="Your email address"
        name="mail"
    value={email_id}
    onChange={((e)=> setEmail(e.target.value))}
      />

    { loading && <CustomLoader /> }
     <span
     style={{width:"100%"}}
      onClick={() => handleNewsletter()}> <input   type="submit" defaultValue="Subscribe" className="subscribe" /></span>
      <p className="text">We won’t send you spam. Unsubscribe at any time.</p>
    </div>
  </div>
       <ToastContainer
        position='top-right'
      />

</section>
      </>
		);
}