import { fork, put, all, takeLatest } from 'redux-saga/effects';

// Login Redux States
import * as actionTypes from './actionTypes';
import axios from 'axios';

//AUTH related methods
import API, { BASE_URL } from '../../../services/axiosInstance';
import { stringifyErrorMessage } from '../../../utils';


function* loginAdminSaga({ payload: { user, history } }) {
    try {
        yield put({type: actionTypes.SET_LOGIN_LOADING});
        const { data } = yield API.post('/system-user/login', user);
        yield put({ type: actionTypes.LOGIN_ADMIN_SUCCESS,payload: data.data});
        history.push('/blog-dashboard');
    } catch (error) {
        yield put({ type: actionTypes.LOGIN_ADMIN_FAILURE,payload: stringifyErrorMessage(error)});
    }
}

function* logoutAdminSaga({ payload: { history } }) {
    try {
        const response = yield API.get('/system-user/logout');
        yield put({type:actionTypes.LOGOUT_ADMIN_SUCCESS,payload:response});
        history.push('/login');
    } catch (error) {
        yield put({type:actionTypes.LOGOUT_ADMIN_FAILURE,payload:stringifyErrorMessage(error)});
    }
}

function* logoutBlockedUserSaga({payload:{ history,token} }) {
  try {
    const response  = yield axios.get(`${BASE_URL}/users/logout`,{
      headers:{
        'Authorization':`Bearer ${token}`
      }
    })
    yield put({type:actionTypes.LOGOUT_BLOCKED_USER_SUCCESS,payload:response});
    history.push('/login')
  } catch (error) {
    yield put({type:actionTypes.LOGOUT_BLOCKED_USER_FAILURE,payload:stringifyErrorMessage(error)});
  }
}


export function* watchLoginAdmin() {
    yield takeLatest(actionTypes.LOGIN_ADMIN, loginAdminSaga)
}

export function* watchLogoutAdmin() {
    yield takeLatest(actionTypes.LOGOUT_ADMIN, logoutAdminSaga)
}

export function* watchLogoutBlockedUser() {
    yield takeLatest(actionTypes.LOGOUT_BLOCKED_USER, logoutBlockedUserSaga)
}


function* loginSaga() {
    yield all([
        fork(watchLoginAdmin),
        fork(watchLogoutAdmin),
        fork(watchLogoutBlockedUser),
    ]);
}

export default loginSaga;