import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCouponsBasedOnDomainAction,
  getRecommendBannerAction,
} from "../../../redux/actions";
import { getCouponByIdAction } from "../../../redux/actions";
import { getImageUrl } from "../../../utils";
import "../../../assets/css/home4.css";
//import Slider from "react-carousel-responsive";
//import "react-carousel-responsive/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "nuka-carousel";
import CentralCouponRedirect from "../../../utils/couponRedirect";
import { BASE_URL } from "../../../services/axiosInstance";
import Slider from "react-slick";


const DealSectionOne = () => {
  const dispatch = useDispatch();

  const { couponbaseondomainList } = useSelector(
    (state) => state.CouponBasedOnDomain
  );
  const { getRecommendBanner } = useSelector((state) => state.Coupon);

  const [useRedirectComponent, setUseRedirectComponent] = useState(false);
  var HorizontalBanner = getRecommendBanner?.filter(function (el) {
    return el.banner_dimension === "120 * 600";
  });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed:2000
  };

  useEffect(() => {
    dispatch(getRecommendBannerAction({ dimension: "120 * 600" }));
  }, [dispatch]);

  useEffect(() => {
    function fetch() {
      dispatch(getCouponsBasedOnDomainAction());
    }
    fetch();
  }, [dispatch]);

  return (
    <section id="page" class="container" style={{ paddingLeft: "0" }}>
    {useRedirectComponent && (
      <CentralCouponRedirect
        e={useRedirectComponent.e}
        coupon={useRedirectComponent.o}
        setUseRedirectComponent={setUseRedirectComponent}
      ></CentralCouponRedirect>
    )}
      <div style={{ marginBottom: "2%" }}>
        <h1 className="h4-section-header" style={{ maxWidth: "14rem" }}>
          RECOMMENDED DEALS &nbsp;
          <i className="fa fa-gift"></i>
        </h1>
      </div>
      <div className="grid-container-h4-vb-mt" style={{ maxHeight: "100%" }}>
        <div
          id="store-tab-banner"
          className="cursor-pointer mobile-hidden-banner-h4"
          style={{ marginLeft: "3%" }}
        >
          <p
            style={{
              color: "white",
              fontSize: ".01px",
              padding: "0",
              lineHeight: ".01px",
            }}
          >
            .
          </p>
          <Slider
            HorizontalBanner={HorizontalBanner}
           {...settings}
          >
            {HorizontalBanner &&
              HorizontalBanner?.map((slide, i) => (
                <div
                  key={i}
                  data-background={slide?.image_upload}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {slide?.banner_dimension === "120 * 600" ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src={
                        slide?.image_url ||
                        `${BASE_URL}/${slide?.image_upload}`
                      }
                      alt=""
                      onClick={() => window.open(slide?.redirect_url, "_blank")}
                    />
                  ) : null}
                </div>
              ))}
          </Slider>
        </div>

        <Carousel>
          <div className="grid-container2">
            {couponbaseondomainList &&
              couponbaseondomainList.length > 0 &&
              couponbaseondomainList.slice(0, 9).map((o, i) => {
                return (
                  <>
                    <div class="card">
                      {o?.category !== "" && (
                        <span
                          class="tag2 tag2-purple"
                          style={{
                            backgroundColor: "#DC2626",
                            fontSize: "9px",
                          }}
                        >
                          <i class="ti-tag"></i>
                          {o?.store?.name
                            ? o?.store?.name
                            : o?.category?.length > 28
                            ? o?.category?.substring(0, 28) + "..."
                            : o?.category}
                        </span>
                      )}
                      <div class="card-header">
                        <img src={getImageUrl(o?.store?.image)} alt="" />
                      </div>
                      <div class="card-body">
                        <p
                          style={{
                            paddingLeft: "0px",
                            fontSize: "13px",
                            color: "#7F1D1D",
                            fontWeight: "800",
                            marginBottom: "2px",
                          }}
                        >
                          Expiry Date: {o?.expiry_date?.slice(0, 10)}
                        </p>
                        <strong style={{ color: "#DC2626" }}>
                          {o?.name?.slice(0, 70)}
                        </strong>
                        {o?.name?.length < 30 && <br />}
                      </div>
                      <button
                        id="show-code"
                        style={{
                          backgroundColor: "#7F1D1D",
                          color: "#fff",
                          borderRadius: "1px",
                          border: "1.5px dotted white",
                          lineHeight: "20px",
                        }}
                        // onClick={(e) => {
                        // //   e.preventDefault();
                        // //   dispatch(getCouponByIdAction({ coupon_id: o?._id }));
                        // //   window.open(
                        // //     `/couponsredirect/coupon/${
                        // //       o?.store?.slug?.replace(" ", "") ||
                        // //       o?.store?.name?.replace(" ", "")
                        // //     }/${o?._id}`,
                        // //     "_blank"
                        // //   );
                        // // }}
                        onClick={(e) => {
                          e.preventDefault();
                          setUseRedirectComponent({ e, o });
                        }}
                      >
                        <i
                          style={{ marginRight: "2px" }}
                          class="fa fa-gift"
                        ></i>{" "}
                        Visit offer
                      </button>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="grid-container2">
            {couponbaseondomainList &&
              couponbaseondomainList.length > 0 &&
              couponbaseondomainList.slice(9, 18).map((o, i) => {
                return (
                  <>
                    <div class="card">
                      {o?.category !== "" && (
                        <span
                          class="tag2 tag2-purple"
                          style={{
                            backgroundColor: "#DC2626",
                            fontSize: "9px",
                          }}
                        >
                          <i class="ti-tag"></i>
                          {o?.store?.name
                            ? o?.store?.name
                            : o?.category?.length > 28
                            ? o?.category?.substring(0, 28) + "..."
                            : o?.category}
                        </span>
                      )}
                      <div class="card-header">
                        <img src={getImageUrl(o?.store?.image)} alt="" />
                      </div>
                      <div class="card-body">
                        <p
                          style={{
                            paddingLeft: "0px",
                            fontSize: "13px",
                            color: "#7F1D1D",
                            fontWeight: "800",
                            marginBottom: "2px",
                          }}
                        >
                          Expiry Date: {o?.expiry_date?.slice(0, 10)}
                        </p>
                        <strong style={{ color: "#B91C1C" }}>
                          {o?.name?.slice(0, 70)}
                        </strong>
                        {o?.name?.length < 50 && <br />}
                      </div>
                      <button
                        id="show-code"
                        style={{
                          backgroundColor: "#7F1D1D",
                          color: "#fff",
                          borderRadius: "1px",
                          border: "1.5px dotted white",
                          lineHeight: "20px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(getCouponByIdAction({ coupon_id: o?._id }));
                          window.open(
                            `/couponsredirect/coupon/${
                              o?.store?.slug?.replace(" ", "") ||
                              o?.store?.name?.replace(" ", "")
                            }/${o?._id}`,
                            "_blank"
                          );
                        }}
                      >
                        <i
                          style={{ marginRight: "2px" }}
                          class="fa fa-gift"
                        ></i>{" "}
                        Visit offer
                      </button>
                    </div>
                  </>
                );
              })}
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default DealSectionOne;
