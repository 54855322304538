import * as actionTypes from "./actionTypes";

export const postNewsletteraction = (payload) => {
  return {
    type: actionTypes.POST_NEWSLETTER_ACTION,
    payload,
  };
};
export const resetNewsletterStateAction = () => {
  return {
    type: actionTypes.RESET_NEWSLETTER_STATE
  };
};