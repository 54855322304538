import { combineReducers } from "redux";
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import Category from "./category/reducer";
import CouponBasedOnDomain from "./couponbaseondomain/reducer";
import Stores from "./stores/reducer";
import Website from "./website/reducer";
import CouponUrl from "./couponUrl/reducer";
import Contact from "./contact/reducer";
import Coupon from "./coupons/reducer";
import Newsletter from "./Newsletter/reducers";

const rootReducer = combineReducers({
  Login,
  Register,
  Category,
  CouponBasedOnDomain,
  Stores,
  Website,
  CouponUrl,
  Contact,
  Coupon,
  Newsletter
});

export default rootReducer;
