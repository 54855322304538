import { useSelector, useDispatch } from "react-redux";
import React from 'react';
import { getTopVisitedStores } from "../../../redux/stores/actions";
import {Link} from 'react-router-dom';
import { getImageUrl } from "../../../utils";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.png";
import "../../../assets/css/home2.css";


export default function MostTrending() {

	const dispatch = useDispatch();

	 React.useEffect(()=>{
	 	dispatch(getTopVisitedStores());
	 }, [dispatch])

   const addDefaultSrc = (e) => {
    e.target.src = PlaceHolderImg;
   }


  const { topRatedStores } = useSelector((state) => state.Stores);
	return(
    <section id="page-mt" class="container" >
    
<div class="thirteen">
  <h1 style={{background:"#eeee10"}}> 
<i style={{marginRight:"2px"}} class="fa fa-bolt"></i> Most trending stores</h1>
</div>
          <div className="grid-container">
                {topRatedStores && topRatedStores.slice(0, 12).map((data, key) => (
                  <div key={key}>
                    <div className="featured-stores" id="most-trending-n" style={{backgroundColor:"white", borderRadius: "10px",}}>
                      <Link to={`/store/${data.store_details.slug}`}>
                        <img onError={e => addDefaultSrc(e)} src={getImageUrl(data?.store_details.image,PlaceHolderImg)}
                          style={{height: "100%",width: "100%", objectFit: "contain",}}
                          alt=''
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              </section>
		)
}