import React, { memo } from "react";
import MainSectionContent from "./MainSectionContent";
import Carousel from "nuka-carousel";
import { getImageUrl } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {getRecommendBannerAction} from "../../../redux/actions";

const MainSection = () => {
  const dispatch = useDispatch();

  const { getRecommendBanner } = useSelector((state) => state.Coupon);

  React.useEffect(() => {
    dispatch(getRecommendBannerAction());
  }, [dispatch]);

  const items = React.useMemo(() => {
    let arr = [];
    getRecommendBanner?.forEach((i) => {
      if (i.banner_dimension === "300 * 250") {
        let obj = {};
        obj.src = getImageUrl(i.image_upload);
        obj.url = i.image_url;
        obj.altText = "ad";
        arr.push(obj);
      }
    });
    return arr;
  }, [getRecommendBanner]);

  return (
    <div>
      <div className="grid-container-s-3 cursor-pointer" id="main-section-n-3">
        <div id="carousel-div-3 cursor-pointer">
          <Carousel>
            {items.map((o) => {
              return (
                <img
                  id="slider-img-s-3"
                  src={o.url || o.src}
                  alt={o.alt}
                  style={{ width: "100%" }}
                  onClick={() => window.open(o?.redirect_url, "_blank")}
                />
              );
            })}
          </Carousel>
        </div>
        <MainSectionContent />
      </div>
    </div>
  );
};

export default memo(MainSection);
