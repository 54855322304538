import * as actionTypes from "./actionTypes";

const initialState = {
  websiteData:[],
  domainData:null,
  error: null,
  errorMessage:'',
  success:false,
  successMessage:'',
  loading: false,
  googleVerificationTag:'',
  footerJs:'',
  googleVerificationTagLoading:false,
  adBanners: [],
  getRecommendBanner2: [], 
  featureCards: [],  //getting it as an object instead of array for some reason
};

const Stores = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WEBSITE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_WEBSITE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        websiteData: action.payload
      };
    case actionTypes.SET_GOOGLE_VERIFICATION_CODE_LOADING:
      return {
        ...state,
        googleVerificationTagLoading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_WEBSITE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        websiteData:[]
      };
      case actionTypes.GET_AD_BANNER_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        adBanners: action.payload
      };
    case actionTypes.GET_AD_BANNER_BY_DOMAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        adBanners:null
      };
      case actionTypes.GET_FOOTER_SUCCESS:
      return {
        ...state,
        loading: false,
        websiteData: action.payload
      };
    
    case actionTypes.GET_FOOTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        websiteData:[]
      };
    case actionTypes.GET_WEBSITE_DATA_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        domainData: action.payload
      };
    case actionTypes.GET_WEBSITE_DATA_BY_DOMAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        domainData:null
      };
    case actionTypes.GET_GOOGLE_VERIFICATION_CODE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          googleVerificationTag: action.payload.google_verification_tag,
          footerJs:action.payload.footer_js,
          googleVerificationTagLoading:false,
        };
    case actionTypes.GET_GOOGLE_VERIFICATION_CODE_DATA_FAILURE:
          return {
            ...state,
            loading: false,
            error: true,
            errorMessage: action.payload,
            domainData:null,
            googleVerificationTagLoading:false,
        };  
      case actionTypes.RECOMMEND_BANNER_BY_DOMAIN_SUCCESS_TWO:
      return {
        ...state,
        loading: false,
        error: false,
        getRecommendBanner: action.payload,
      };
    case actionTypes.RECOMMEND_BANNER_BY_DOMAIN_FAILURE_TWO:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.GET_FEATURE_CARDS_SUCCESS:
    return{
      ...state,
      loading: false,
      featureCards: action.payload,
      error: false,
    };
    case actionTypes.GET_FEATURE_CARDS_FAILURE:
      return{
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.RESET_WEBSITE_SUCCESS_STATUS:
      return { ...state, loading: false, error: false, errorMessage: "", successMessage:'', success:false };
    default:
      return { ...state };
  }
};

export default Stores;
