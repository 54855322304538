export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORYE';
export const GET_ALL_CATEGORY_SUCCESS = 'GET_ALL_CATEGORY_SUCCESS';
export const GET_ALL_CATEGORY_FAILURE = 'GET_ALL_CATEGORY_FAILURE';

export const GET_COUPONS_BY_CATEGORY = 'GET_COUPONS_BY_CATEGORY';
export const GET_COUPONS_BY_CATEGORY_SUCCESS = 'GET_COUPONS_BY_CATEGORY_SUCCESS';
export const GET_COUPONS_BY_CATEGORY_FAILURE = 'GET_COUPONS_BY_CATEGORY_FAILURE';


export const RESET_CATEGORY_STATUS='RESET_CATEGORY_STATUS';

export const SET_CATEGORY_LOADING='SET_CATEGORY_LOADING';


