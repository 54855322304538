import { fork, put, all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes'
import API from '../../../services/axiosInstance';
import { stringifyErrorMessage } from '../../../utils/index'


function* registerUser({ payload }) {
    try {
      yield put({type: actionTypes.SET_REGISTER_LOADING});
      const { data } = yield API.post('/system-user/register',payload.user);
      yield put({type:actionTypes.REGISTER_SUCCESSFUL, payload: data.data})
      if(data){
        payload?.history.push('/')
      }
    } catch (error) {
      yield put({type: actionTypes.REGISTER_FAILED, payload:stringifyErrorMessage(error)});
    }
}

export function* watchUserRegister() {
    yield takeLatest(actionTypes.REGISTER, registerUser);
}

function* registerSaga() {
    yield all([fork(watchUserRegister)]);
}

export default registerSaga;