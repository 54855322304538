import React from "react";
import MainSectionContent from "./MainSectionContent";
import Carousel from "nuka-carousel";
import { getImageUrl } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getRecommendBannerAction } from "../../../redux/actions";

const MainSection = () => {
  const dispatch = useDispatch();

  const { getRecommendBanner } = useSelector((state) => state.Coupon);

  React.useEffect(() => {
    dispatch(getRecommendBannerAction());
  }, [dispatch]);

  const items = React.useMemo(() => {
    let arr = [];
    getRecommendBanner?.forEach((i) => {
      if (i.banner_dimension === "300 * 250") {
        let obj = {};
        obj.src = getImageUrl(i.image_upload);
        obj.url = i.image_url;
        obj.altText = "ad";
        arr.push(obj);
      }
    });
    return arr;
  }, [getRecommendBanner]);

  return (
    <div>
      <div className="grid-container-s cursor-pointer" id="main-section-n">
        <MainSectionContent />
        <div
          id="carousel-div"        >
          <Carousel>
            {items.map((o) => {
              return <img onClick={() => window.open(o?.redirect_url, "_blank")} id="slider-img-s" src={o.url || o.src} alt={o.alt} />;
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
