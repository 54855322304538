import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../../utils";
import { getNewlyAddedStores } from "../../../redux/stores/actions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "nuka-carousel";
import { getTopVisitedStores } from "../../../redux/stores/actions";

const NewlyAddedStores = () => {
  const dispatch = useDispatch();

  const { newlyAddedStores } = useSelector((state) => state.Stores);
  const { topRatedStores } = useSelector((state) => state.Stores);

  const [activeTab, setActiveTab] = React.useState("new");
  const [storeList, setStoreList] = React.useState([]);

  React.useEffect(() => {
    dispatch(getNewlyAddedStores());
    dispatch(getTopVisitedStores());
  }, [dispatch]);

  React.useEffect(()=>{
    if(activeTab === "new"){
      setStoreList(newlyAddedStores);
    }else{
      setStoreList(topRatedStores);
    }
  }, [activeTab, newlyAddedStores, topRatedStores])

  return (
    <section
      id="page"
      class=" cursor-pointer"
      style={{ marginBottom: "10px", background: "#cea1fa50", padding:"7.5px 10px 10px", borderRadius:"5px"}}
    >
      <div  id="h5-newly-tabs"
        style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}
      >
        <div>
          <h1
            className="h4-section-header"
            style={{
              maxWidth: "14rem",
              background: activeTab === "new" ? "#9200da" : "#cea1fa",
              padding: "8px 10px",
              border: "1px solid #9200da30",
            }}
            onClick={()=>setActiveTab("new")}
          >
            NEWLY ADDED STORES &nbsp;<i className="fa fa-film"></i>
          </h1> 
        </div>
        <div> 
          <h1
            className="h4-section-header"
            style={{
              maxWidth: "15rem",
              background: activeTab === "trending" ? "#9200da" : "#cea1fa",
              border: "1px solid #9200da30",
              padding: "8px 10px",
            }}
            onClick={()=>setActiveTab("trending")}
          >
            MOST TRENDING STORES &nbsp;<i className="fa fa-fire"></i>
          </h1>
        </div>
      </div>
      <div className="newly-banner-h4">
        <Carousel>
          <div className="carousel-stores-h4-grid-new">
            {storeList &&
              storeList.length > 0 &&
              storeList.slice(0, 12).map((data) => {
                return (
                  <div class="card" style={{ borderRadius: "5px" }}>
                    <div
                      class="card-header"
                      style={{ height: "100px", padding: "5px" }}
                    >
                      <Link to={`/store/${data?.slug}`}>
                        <img
                          src={getImageUrl(data?.image || data?.store_details?.image)}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          loading="lazy"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div
                      class="card-body"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <strong
                        style={{
                          color: "#9200da",
                          fontSize: "12px",
                          margin: "auto",
                        }}
                      >
                        {data?.slug?.toUpperCase() || data?.store_details?.slug}
                      </strong>
                    </div>
                    <div
                      class="card-body"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <strong
                        style={{
                          color: "red",
                          fontSize: "12px",
                          margin: "auto",
                          fontWeight: "700",
                        }}
                      >
                        {Math.floor(1000 + Math.random() * 9000)} visits
                      </strong>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="carousel-stores-h4-grid-new">
            {storeList &&
              storeList.length > 0 &&
              storeList.slice(12, 24).map((data, i) => {
                return (
                  <div
                    class="card"
                    style={{
                      borderRadius: "1px",
                      border: "1px dotted #EF4444",
                    }}
                  >
                    <div
                      class="card-header"
                      style={{ height: "100px", padding: "5px" }}
                    >
                      <Link to={`/store/${data?.slug}`}>
                        <img
                          src={getImageUrl(data?.image || data?.store_details?.image)}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div class="card-body">
                      <strong style={{ color: "#7F1D1D", fontSize: "12px" }}>
                        {data?.slug?.toUpperCase() || data?.store_details?.slug}
                      </strong>
                    </div>

                    <div class="card-body">
                      <strong style={{ color: "#7F1D1D", fontSize: "12px" }}>
                        Coupon Count: {data?.visit_count}
                      </strong>
                    </div>
                  </div>
                );
              })}
          </div>
        </Carousel>
      </div>
    </section>
  );
}
export default memo(NewlyAddedStores);
