import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getGoogleVerificationCodeByDomainAction,
  getWebsiteDataByDomainAction,
  getStoresBasedOnDomainAction,
} from "../../redux/actions";
import { getCouponListByNameAction } from "../../redux/actions";
import { getStoresByNameAction } from "../../redux/stores/actions";
import { Link, useHistory } from "react-router-dom";
import { NavItem, Navbar, Nav } from "react-bootstrap";
import { getImageUrl } from "../../utils";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../services/axiosInstance";
import parse from "html-react-parser";
import { scriptEncoding } from "../../utils/helpers";
import "../../assets/css/header.css";
import useSessionStorage from "../../hooks/useSessionStorage";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    domainData,
    loading: loadingWebsiteData,
    googleVerificationTag,
    googleVerificationTagLoading,
    footerJs,
  } = useSelector((state) => state.Website);

  const [domainDataSession, setDomainDataSession] = useSessionStorage(
    "domainDataSession",
    {}
  );

  useEffect(() => {
    dispatch(
      getWebsiteDataByDomainAction(setDomainDataSession, domainDataSession)
    );
    dispatch(getStoresBasedOnDomainAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGoogleVerificationCodeByDomainAction());
  }, [dispatch]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    dispatch(getCouponListByNameAction({ name: searchValue }));
    dispatch(getStoresByNameAction({ name: searchValue }));
    enterSearch.current.click();
  };

  const enterSearch = React.useRef();

  const handleRedirect = () => {
    history.push("/");
  };

  return (
    <header>
      <Helmet>
        <title>{domainData?.name}</title>
        {domainData?.header_html && parse(domainData?.header_html)}
        <link rel="canonical" href="/" />
        {domainData?.meta_tag}
        <meta name="description" content={domainData?.meta_description} />
        {domainData?.favicon && (
          <link
            rel="icon"
            type="image/png"
            href={`${BASE_URL}/${domainData?.favicon}`}
            sizes="16x16"
          />
        )}

        {!googleVerificationTagLoading && googleVerificationTag
          ? scriptEncoding(googleVerificationTag)
          : null}
        {!googleVerificationTagLoading && footerJs
          ? scriptEncoding(footerJs)
          : null}
      </Helmet>
      <Navbar
        inverse
        collapseOnSelect
        style={{
          padding: "9.9px",
          backgroundColor: loadingWebsiteData
            ? "transparent"
            : domainData?.header_color || "transparent",
          // eslint-disable-next-line no-dupe-keys
          padding: "0.5rem 1rem",
          borderRadius: "0",
          border: "0",
          position: "relative",
        }}
      >
        <div onClick={handleRedirect}>
          <Link to="/">
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/" style={{ position: "absolute" }}>
                  {domainData?.logo ? (
                    <img
                      src={getImageUrl(domainData?.logo, logo)}
                      alt=""
                      className="img-responsive"
                      style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "150px",
                      }}
                    />
                  ) : (
                    <img alt="" className="img-responsive" />
                  )}
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
          </Link>
        </div>
        <Navbar.Collapse>
          <Nav>
            <div
              id="searchBar"
              className="input-group"
              style={{ whiteSpace: "nowrap", paddingTop: "3%" }}
            >
              <input
                id="search-input"
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                value={searchValue}
                type="text"
                className="form-control shadow-none"
                placeholder="Search"
              />
              <div
                className="input-group-append"
                style={{ display: "inline-block" }}
              >
                <Link to="/searchpage">
                  <button
                    ref={enterSearch}
                    style={{
                      backgroundColor: "inherit",
                      borderColor: "white",
                      borderLeft: "none",
                      border: "2px solid white",
                      borderRadius: "0 4px 4px 0",
                      fontWeight: "bold",
                      display: "inline",
                      fontSize: "1.05rem",
                      padding: "20% 30% 5% 30%",
                    }}
                    onClick={handleSearch}
                    class="btn btn-danger shadow-none"
                    type="button"
                  >
                    <i className="ti-search"></i>
                  </button>
                </Link>
              </div>
            </div>
          </Nav>
          <Nav pullRight>
            <NavItem eventKey={1} style={{ color: "#FFF" }}>
              <Link to="/" style={{ color: "#FFF" }}>
                <span className="nav-icon">
                  {" "}
                  <i className="ti-home"></i>
                </span>
                Home
              </Link>
            </NavItem>
            <NavItem eventKey={2} style={{ color: "#FFF" }}>
              <Link to="/store" style={{ color: "#FFF" }}>
                <span className="nav-icon">
                  <i className=" ti-clipboard"></i>
                </span>
                Store
              </Link>
            </NavItem>
            <NavItem eventKey={2}>
              <Link to="/aboutus" style={{ color: "#FFF" }}>
                <span className="nav-icon">
                  {" "}
                  <i className=" ti-list-ol"></i>
                </span>{" "}
                About Us
              </Link>
            </NavItem>
            <NavItem eventKey={2}>
              <Link to="/categories" style={{ color: "#FFF" }}>
                {" "}
                <span className="nav-icon">
                  {" "}
                  <i className="ti-folder"></i>
                </span>{" "}
                Categories
              </Link>
            </NavItem>
            <NavItem eventKey={2}>
              <Link to="/contactus" style={{ color: "#FFF" }}>
                {" "}
                <span className="nav-icon">
                  {" "}
                  <i className="ti-email"></i>
                </span>{" "}
                Contact Us
              </Link>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
