import React from 'react'
import DealSectionOne from './DealSectionOne'
import MainSection from './MainSection'
import NewlyAddedStores from './NewlyAddedStores'
import MostTrending from './MostTrending'
import HBanner from "./Banners3";
import NewsLetter from './NewsLetter'
import {getEndUserAction} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";


const Home = () => {

  const dispatch = useDispatch();

  const { getEndUser } = useSelector((state) => state.Coupon);

   React.useEffect(() => {
    dispatch(getEndUserAction());
  }, [dispatch]);
  
  return (
    <div className="container" style={{background:"#FAEBD750", paddingTop:"1px"}}>
      <MainSection />
      <MostTrending  />
      <HBanner getEndUser={getEndUser} />
      <DealSectionOne getEndUser={getEndUser} />
      <NewlyAddedStores />
      <NewsLetter />
    </div>
  )
}

export default Home
