import React, { memo } from 'react'
import { Link } from 'react-router-dom'

const MainSectionSidebarItem = ({category, count}) => {
    return (
        
             <li>
              <Link to={`/category/${category}`}>
                <i class="ti-arrow-circle-right"></i>
                {category}
                <span class="sidebar-badge">{count}</span>
              </Link>
            </li>
        
    )
}

export default memo(MainSectionSidebarItem)
