import * as actionTypes from "./actionTypes";

export const getCouponByIdAction = (payload) => ({
  type: actionTypes.GET_COUPON_BY_ID,
  payload,
});

export const resetCouponsAction = () => ({
  type: actionTypes.RESET_COUPON_BY_ID,
});

export const getCouponListByNameAction = (payload) => ({
  type: actionTypes.GET_COUPON_LIST_BY_NAME,
  payload,
});

export const getCouponListByWebsiteAction = (payload) => ({
  type: actionTypes.GET_COUPON_LIST_BY_WEBSITE,
  payload,
});
export const getTestAction = (payload) => ({
  type: actionTypes.GET_TEST,
  payload,
});

export const getActiveStoresBasedOnDomainAction = (payload) => ({
  type: actionTypes.GET_ACTIVE_STORES_BASED_ON_DOMAIN,
  payload,
});

export const getEndUserAction = () => ({
  type: actionTypes.GET_END_USER,
});
// /end-user/advertisement/recommendBannerByDomain
export const getRecommendBannerAction = (payload) => ({
  type: actionTypes.RECOMMEND_BANNER_BY_DOMAIN,
  payload,
});
