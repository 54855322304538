export const GET_COUPON_BASED_ON_DOMAIN = 'GET_COUPON_BASED_ON_DOMAINE';
export const GET_COUPON_BASED_ON_DOMAIN_SUCCESS = 'GET_COUPON_BASED_ON_DOMAIN_SUCCESS';
export const GET_COUPON_BASED_ON_DOMAIN_FAILURE = 'GET_COUPON_BASED_ON_DOMAIN_FAILURE';



export const RESET_COUPON_BASED_ON_DOMAIN_STATUS='RESET_COUPON_BASED_ON_DOMAIN_STATUS';

export const SET_COUPON_BASED_ON_DOMAIN_LOADING='SET_COUPON_BASED_ON_DOMAIN_LOADING';


