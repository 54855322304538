import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecommendBannerAction,
} from "../../../redux/actions";
import { getCouponByIdAction } from "../../../redux/actions";
import { getImageUrl } from "../../../utils";
import "../../../assets/css/home4.css";
//import Slider from "react-carousel-responsive";
//import "react-carousel-responsive/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { postNewsletteraction, resetNewsletterStateAction} from "../../../redux/Newsletter/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCouponsBasedOnDomainAction, } from "../../../redux/actions";
import CentralCouponRedirect from "../../../utils/couponRedirect";
import { BASE_URL } from "../../../services/axiosInstance";
import Slider from "react-slick";


const DealSectionOne = () => {
  const dispatch = useDispatch();

  
  const [email_id, setEmail] = React.useState("");

  const { couponbaseondomainList } = useSelector(
    (state) => state.CouponBasedOnDomain
  );
  const { successMessage, loading, errorMessage } = useSelector(
    (state) => state.Newsletter
  );
  const { getRecommendBanner } = useSelector((state) => state.Coupon);
  const [useRedirectComponent, setUseRedirectComponent] = useState(false);

  
const validate = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

  const handleNewsletter = (e) => {

    e.preventDefault();

  if(!(validate(email_id))){ 
    toast.error("Please provide a valid email address.")
    return;
  }
  
  setClassN("Subscribe-h5-sub Subscribe-h5-sub--loading")
  setTimeout(()=>{
    setClassN("Subscribe-h5-sub  Subscribe-h5-sub--loading Subscribe-h5-sub--complete");
  }, 3000)

    dispatch(postNewsletteraction({ email_id }));

  };

  const [classN, setClassN] = React.useState("Subscribe-h5-sub");

  var HorizontalBanner = getRecommendBanner?.filter(function (el) {
    return el.banner_dimension === "120 * 600";
  });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed:2000
  };

  useEffect(() => {
    dispatch(getRecommendBannerAction({ dimension: "120 * 600" }));
    dispatch(getCouponsBasedOnDomainAction());
  }, [dispatch]);

React.useEffect(()=>{

   if(errorMessage){
     toast.error(errorMessage);
   dispatch(resetNewsletterStateAction());
   setClassN("Subscribe-h5-sub");
   }
   
    dispatch(resetNewsletterStateAction());

 },[dispatch, errorMessage, loading, successMessage]) 

  return (
    <section id="page" class="container" style={{ paddingLeft: "0" }}>
    {useRedirectComponent && (
      <CentralCouponRedirect
        e={useRedirectComponent.e}
        coupon={useRedirectComponent.o}
        setUseRedirectComponent={setUseRedirectComponent}
      ></CentralCouponRedirect>
    )}
      <div  className="container-deals-h5">
        <div className="info">
          <h1 style={{color:"#553098"}}>Top Deals</h1>
          <span style={{fontWeight:"600"}}>Find the top deals from the best stores.</span>
        </div>
      </div>
      <div className="grid-container-h4-vb-mt" style={{ maxHeight: "100%" }}>
        <div>
      <div className="Container-h5-sub">
  <div className={classN}>
    <h3>Subscribe to our newsletter</h3>
    <p>Best offers from the top stores delivered straight to your inbox</p>
    <form>
      <input type="text" placeholder="Email Address" 
    value={email_id}
    onChange={((e)=> setEmail(e.target.value))}
    />
      <button onClick={(e)=>handleNewsletter(e)}>Subscribe</button>
    </form>
    <div className="Loading">
      <div className="LoadingDot" />
      <div className="LoadingDot" />
      <div className="LoadingDot" />
      <div className="LoadingDot" />
      <span>Subscribing</span>
    </div>
    <div className="Complete">
      <div className="Tick">
        <svg width="32px" height="25px" viewBox="0 0 32 25">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Artboard"
              transform="translate(-384.000000, -217.000000)"
              fillRule="nonzero"
              fill="#FFFFFF"
            >
              <g id="Group" transform="translate(360.000000, 189.000000)">
                <path
                  d="M27.4142136,40.5857864 C26.633165,39.8047379 25.366835,39.8047379 24.5857864,40.5857864 C23.8047379,41.366835 23.8047379,42.633165 24.5857864,43.4142136 L34,52.8284271 L55.4142136,31.4142136 C56.1952621,30.633165 56.1952621,29.366835 55.4142136,28.5857864 C54.633165,27.8047379 53.366835,27.8047379 52.5857864,28.5857864 L34,47.1715729 L27.4142136,40.5857864 Z"
                  id="Path-2"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <h4>Thank you for subscribing</h4>
      <span>You will receive a confirmation email shortly</span>
    </div>
  </div>
</div>
        <div
          id="store-tab-banner"
          className="cursor-pointer mobile-hidden-banner-h4"
          style={{ marginLeft: "3%", }}
        >
          <p
            style={{
              color: "white",
              fontSize: ".01px",
              padding: "0",
              lineHeight: ".01px",
            }}
          >
            .
          </p>
          <Slider
            HorizontalBanner={HorizontalBanner}
            {...settings}
          >
            {HorizontalBanner &&
              HorizontalBanner?.map((slide, i) => (
                <div
                  key={i}
                  data-background={slide?.image_upload}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {slide?.banner_dimension === "120 * 600" ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src={
                        slide?.image_url ||
                        `${BASE_URL}/${slide?.image_upload}`
                      }
                      alt=""
                      onClick={() => window.open(slide?.redirect_url, "_blank")}
                      loading="lazy"
                    />
                  ) : null}
                </div>
              ))}
          </Slider>
        </div>
        </div>

          <div className="grid-container2-deals-h5">
            {couponbaseondomainList &&
              couponbaseondomainList.length > 0 &&
              couponbaseondomainList.slice(0, 9).map((o, i) => {
                return (
                  <>
                    <div className="column h5-deals-col" >
                      {/* Post*/}
                      <div className="post-module">
                        {/* Thumbnail*/}
                        <div className="thumbnail">
                          <div className="date" style={{ fontSize: "1.9rem" }}>
                            <i title="Verified" className=" ti-thumb-up"></i>
                          </div>
                          {console.log("new data>", o)}
                          <img src={getImageUrl(o?.store?.image)} alt="" />
                        </div>
                        {/* Post Content*/}
                        <div className="post-content">
                          <div className="category">
                            {o?.store?.name
                              ? o?.store?.name
                              : o?.category?.length > 28
                              ? o?.category?.substring(0, 28) + "..."
                              : o?.category}
                          </div>
                          <h1 className="title">Expiry Date: {o?.expiry_date?.slice(0, 10)}</h1>
                          <h2 className="sub_title">
                          {o?.name?.slice(0, 70)}
                          </h2>
                          <p className="description">
                            New York, the largest city in the U.S., is an
                            architectural marvel with plenty of historic
                            monuments, magnificent buildings and countless
                            dazzling skyscrapers.
                          </p>
                          <div className="post-meta">
                            <span className="timestamp">
                              <i className="fa fa-clock-">o</i>Limited time deal
                            </span>
                            <span className="comments">
                              
                        <button
                          id="show-code"
                          style={{
                            backgroundColor: "#7C3AED",
                            color: "#fff",
                            borderRadius: "1px",
                            border: "1.5px dotted white",
                            lineHeight: "20px",
                          }}
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   dispatch(
                          //     getCouponByIdAction({ coupon_id: o?._id })
                          //   );
                          //   window.open(
                          //     `/couponsredirect/coupon/${
                          //       o?.store?.slug?.replace(" ", "") ||
                          //       o?.store?.name?.replace(" ", "")
                          //     }/${o?._id}`,
                          //     "_blank"
                          //   );
                          // }}
                          
                        onClick={(e) => {
                          e.preventDefault();
                          setUseRedirectComponent({ e, o });
                        }}
                        >
                          <i
                            style={{ marginRight: "2px" }}
                            class="fa fa-pen-to-square"
                          ></i>{" "}
                          Show code
                        </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default memo(DealSectionOne);
