export default function ReplaceMacro({input}){

  var months = [
   "January",
   "February",
   "March",
   "April",
   "May",
   "June",
   "July",
   "August",
   "September",
   "October",
   "November",
   "December",
 ];

 var d = new Date();
 var fullYear = d.getFullYear();

 var namedMonth = months[d.getMonth()];
 
 let tempDesc;

if(input){

 tempDesc = input;
 
 //month
  tempDesc = tempDesc.replaceAll( "{month}", namedMonth + " ");
  tempDesc = tempDesc.replaceAll( "{Month}", namedMonth + " ");
  tempDesc = tempDesc.replaceAll( "{MONTH}", namedMonth + " ");

  //year
  tempDesc = tempDesc.replaceAll( "{year}", " " + fullYear);
  tempDesc = tempDesc.replaceAll( "{Year}", " " + fullYear);
  tempDesc = tempDesc.replaceAll( "{YEAR}", " " + fullYear);
}

 return(
     <>
     {tempDesc && 
   <span> {tempDesc.includes("<") ? <span dangerouslySetInnerHTML={{ __html: tempDesc }} ></span> :  <span>{tempDesc}</span> }
     </span>}
     </>
   )
}