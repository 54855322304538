import React, { memo }  from "react";
//import Slider from "react-carousel-responsive";
//import "react-carousel-responsive/dist/styles.css";
import { BASE_URL } from "../../../services/axiosInstance";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Banner({getEndUser}) {

  var HorizontalBanner = getEndUser?.filter(function (el) {
    return el.banner_dimension === "728 * 90";
  });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed:2000
  };


  return (
    <div id="store-tab-banner-h4" className="cursor-pointer container" >
    <p style={{color:"white", fontSize:".01px", padding:"0", lineHeight:".01px"}}>
    .
    </p>
      <Slider
        HorizontalBanner={HorizontalBanner}
       {...settings}
      >
        {HorizontalBanner &&
          HorizontalBanner?.map((slide, i) => (
            <div
              key={i}
              data-background={slide?.image_upload}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {slide?.banner_dimension === "728 * 90" ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={slide?.image_url || `${BASE_URL}/${slide?.image_upload}` }
                  alt=""
                  onClick={()=>window.open(slide?.redirect_url, "_blank")}
                />
              ) : null}
            </div>
          ))}
      </Slider>
    </div>
  );
}

export default memo(Banner);