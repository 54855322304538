import React, { memo, useEffect, useState } from "react";
import darkLogo from "../../../assets/images/logo-dark.png";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getImageUrl } from "../../../utils";
import CustomLoader from "../../UI/CustomLoader";
import ReactGA from "react-ga";
import Macro from "../../../utils/ReplaceMacro";

const Footer = () => {

  const { domainData, loading: loadingWebsiteData } = useSelector(
    (state) => state.Website
  );

  // Analytics
  const location = useLocation();

  useEffect(() => {
    function set() {
      ReactGA.initialize("UA-200647590-1");
      ReactGA.pageview(location.pathname + location.search);
    }
    set();
  }, [location]);


  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <footer id="footer" style={{background:"#8B5CF6", 
    color:"#FEF2F2", padding:"30px 0 20px 0",}}>
      <div className="container">
      <div id="h5-footer-grid" >
          <Link style={{color:"white"}} to="/">
        <div className="h5-footer-li">
          <i className="fa fa-home">
            </i><span style={{fontWeight:"400", fontSize:"1.2rem"}}>Home</span>
            </div>
            </Link>
            
          <Link style={{color:"white"}} to="aboutus">
        <div  className="h5-footer-li">
          <i className="fa fa-book">
            </i><span style={{fontWeight:"400", fontSize:"1.2rem"}}>About Us</span>
            </div>
            </Link>
          <Link style={{color:"white"}} to="contactus">
        <div  className="h5-footer-li">
          <i className="fa fa-envelope">
            </i><span style={{fontWeight:"400", fontSize:"1.2rem"}}>Contact Us</span>
            </div>
            </Link>
          <Link  style={{color:"white"}} to="privacypolicy">
        <div  className="h5-footer-li">
          <i className="fa fa-clipboard">
            </i><span style={{fontWeight:"400", fontSize:"1.2rem"}}>Privacy Policy</span>
            </div>
            </Link>
          <Link  style={{color:"white"}} to="categories">
        <div  className="h5-footer-li">
          <i className="fa fa-grid-2">
            </i><span style={{fontWeight:"400", fontSize:"1.2rem"}}>Categories</span>
            </div>
            </Link>
      </div>
      <div id="h5-footer-foot" style={{display:'flex', justifyContent:"center", alignItems:"center", gap:"10px"}}>
            {loadingWebsiteData ? (
              <CustomLoader
                customStyle={{
                  height: "30px",
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "enter",
                }}
              />
            ) : (
              <img
                src={getImageUrl(domainData?.logo, darkLogo)}
                alt=""
                style={{
                  height: "40px",
                  width: "85px",
                  borderRadius: "5px",
                }}
                loading="lazy"
              />
            )}
            <div style={{fontSize:".9rem", fontWeight:"500"}}>
          <Macro input={domainData?.footer_text?.toUpperCase()?.replace("RED", "white")} /></div>
          </div>
      </div>
      
      {/* {renderHTML(websiteData)} */}
      <button
        className="btn btn-success"
        title="Scroll to top"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          backgroundColor: "#7C3AED",
          borderColor: "white",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1rem",
          padding: "4px 10px 1px 13px",
        }}
        onClick={() => {
        window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    })
      }}
      >
       Top <i style={{fontSize:".8rem",
          fontWeight:"500"}} className="ti-angle-up"></i>
      </button>
    </footer>
  );
};

export default memo(Footer);
