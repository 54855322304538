import { fork, put, all, takeLatest } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import API from "../../services/axiosInstance";
import { stringifyErrorMessage } from "../../utils";

function* getCouponsBasedOnDomainSaga() {
  const params = { resolution: `${window.innerWidth}x${window.innerHeight}` };
  try {
    yield put({ type: actionTypes.SET_COUPON_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get(
      "/api/end-user/coupon/getCouponsBasedOnDomain",
      {
        headers: {
          "domain-name": "malangdo.com",
        },
        params,
      }
    );

    yield put({
      type: actionTypes.GET_COUPON_BASED_ON_DOMAIN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPON_BASED_ON_DOMAIN_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

export function* watchGetCouponsBasedOnDomain() {
  yield takeLatest(
    actionTypes.GET_COUPON_BASED_ON_DOMAIN,
    getCouponsBasedOnDomainSaga
  );
}

function* CouponBasedOnDomainSaga() {
  yield all([fork(watchGetCouponsBasedOnDomain)]);
}

export default CouponBasedOnDomainSaga;
