import React, { useEffect }  from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "nuka-carousel";
import {
  getAdBannerByDomainAction
} from "../../../redux/actions";
import { getImageUrl } from "../../../utils";

const MainSectionContent = () => {


  const dispatch = useDispatch();

  const {  adBanners } = useSelector((state) => state.Website);

  useEffect(()=>{
      dispatch(getAdBannerByDomainAction());
  }, [dispatch])


   const items = React.useMemo(()=>{
    let arr = [];
    adBanners?.forEach((i)=> {
      if(i.banner_dimension === "1480 * 630"){let obj = {};
            obj.src = getImageUrl(i.image_upload);
            obj.url = i.image_url;
            obj.altText = "ad";
            arr.push(obj);}
    });
    return arr;
  }, [adBanners])
 
  return (
    <>
      <Carousel>
      {items.map((o)=>{
        return(
  <img className="cursor-pointer carousel-img" 
  onClick={() => window.open(o?.redirect_url, "_blank")}
   src={o.url || o.src} alt={o.alt}/>
  )
      })}
  </Carousel>
    </>
  );
};

export default MainSectionContent;
