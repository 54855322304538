import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getGoogleVerificationCodeByDomainAction,
  getStoresBasedOnDomainAction,
  getWebsiteDataByDomainAction,
} from "../../../redux/actions";
import { getCouponListByNameAction } from "../../../redux/actions";
import {getStoresByNameAction} from "../../../redux/stores/actions";

import { Link } from "react-router-dom";
import { getImageUrl } from "../../../utils";

const Header = () => {
  const dispatch = useDispatch();

  const { domainData } = useSelector((state) => state.Website);

  useEffect(() => {
    function fetch() {
      dispatch(getStoresBasedOnDomainAction());
    }
    fetch();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGoogleVerificationCodeByDomainAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);


  const[searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = () => {
    dispatch(getCouponListByNameAction({ name: searchValue }));
    dispatch(getStoresByNameAction({ name: searchValue }));
    enterSearch.current.click();
  }

  const enterSearch = React.useRef()

  return (
    <>
      <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{background:"#eeee10"}}>
        <div className="container" style={{maxWidth:"2000px"}}>
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setIsOpen(!isOpen)}
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            {domainData?.logo ? (
              
      <Link to="/">
              <img
                src={getImageUrl(domainData?.logo, logo)}
                alt=""
                className="img-responsive"
                id="logo-nav"
                style={{
                  objectFit: "contain",
                }}
              />
              </Link>
            ) : (
              " "
            )}{" "}
          </div>

          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="bs-example-navbar-collapse-1"
          >
          <div>
            <input
              type="text"
              className="searchTerm"
              placeholder="What are you looking for?"
              style={{
                height: "35px",
                color: "black",
                borderColor: "black",
              }}  onChange={(e)=>setSearchValue(e.target.value)} 
    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSearch()
                        } }}
    value={searchValue}
            />
            <Link to="/searchpage">
            <button
              type="submit"
              className="searchButton"
                ref={enterSearch} 
              style={{
                height: "34.5px",
                paddingBottom:"3px",
                fontSize:"21px",
                backgroundColor: "black",
                borderColor: "black",
              }}
              onClick={handleSearch}
            >
              <i className="fa fa-search"></i>
            </button>
            </Link>
            <button
              id="navbar-en"
              style={{ backgroundColor: "black", color:"white" }}
            >
              <Link to="store">All stores</Link>
            </button>
            </div>
            <ul className="nav navbar-nav navbar-right" id="nav-ul-n">
              <li>
                <Link
                  to="/store"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  Store
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/categories"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  Categories
                </Link>
              </li>
              <li>
                <Link
                  to="/contactus"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div
          className="container"
          id="mobile-hidden-nav"
          style={{ backgroundColor: "black", marginTop: ".5%" }}
        >
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setIsOpen(!isOpen)}
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>{" "}
          </div>

          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="bs-example-navbar-collapse-1"
            style={{background:"black", color:"white"}}
          >
            <ul
              className="nav navbar-nav navbar-right"
              style={{ marginRight: "17%" }}
            >
              <li>
                <Link
                  to="/"
                  className="page-scroll" style={{fontSize:"12px"}}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/store"
                  className="page-scroll" style={{fontSize:"12px"}}
                >
                  Stores
                </Link>
              </li>
              <li 
                  id="h2-nav-res-li">
                <Link
                  to="/aboutus"
                  className="page-scroll" style={{fontSize:"12px"}}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/categories"
                  className="page-scroll" style={{fontSize:"12px"}}
                >
                  Categories
                </Link>
              </li>
              <li>
                <Link
                  to="/contactus"
                  className="page-scroll" style={{fontSize:"12px"}}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/*duplicate content so it doesen't overlap pages*/}
      <nav
        id="menu"
        className="navbar navbar-default navbar-fixed-top"
        style={{ position: "static", marginTop: "1%", paddingTop: "0" }}
      >
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setIsOpen(!isOpen)}
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            {domainData?.logo ? (
              <img
                src={getImageUrl(domainData?.logo, logo)}
                alt=""
                className="img-responsive"
                id="logo-nav"
                style={{
                  objectFit: "contain",
                }}
              />
            ) : (
              " "
            )}{" "}
          </div>

          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="bs-example-navbar-collapse-1"
          >
            <input
              type="text"
              className="searchTerm"
              placeholder="What are you looking for?"
              style={{
                height: "35px",
                color: domainData?.header_color,
                borderColor: domainData?.header_color,
              }}
            />
            <button
              type="submit"
              className="searchButton"
              style={{
                height: "36px",
                paddingBottom: "3px",
                backgroundColor: "black",
                borderColor: "white",
              }}
            >
              <i className="fa fa-search"></i>
            </button>
            <button
              id="navbar-en"
              style={{ backgroundColor: "black", color:"white" }}
            >
              <Link to="store">All stores</Link>
            </button>
            <ul className="nav navbar-nav navbar-right" id="nav-ul-n">
              <li>
                <Link
                  to="/store"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  Store
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/categories"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  Categories
                </Link>
              </li>
              <li>
                <Link
                  to="/contactus"
                  className="page-scroll"
                  style={{ color: domainData?.header_color }}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div
          className="container"
          id="mobile-hidden-nav"
          style={{ backgroundColor: "#f7f8fa", marginTop: ".5%" }}
        >
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={() => setIsOpen(!isOpen)}
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>{" "}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
