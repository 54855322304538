import axios from "axios";

let subDomains = window.location.host.split(".");

let domain_name =
  subDomains.length > 2
    ? window.location.host.replace(`${subDomains[0]}.`, "")
    : window.location.host;

export const BASE_URL =
  domain_name.includes("localhost") || domain_name.includes("web.app")
    ? "https://staging.malangdo.com"
    : "https://api." + domain_name;

const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use((request) => {
  if (request.url.endsWith("login")) return request;

  const token = sessionStorage.getItem("token");

  request.headers["Authorization"] = `Bearer ${token}`;
  request.headers["Content-Type"] = "application/json";
  return request;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      sessionStorage.removeItem("token");
    }
    return Promise.reject(error);
  }
);

export default API;
