import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../utils";
import { getNewlyAddedStores } from "../../redux/stores/actions";
import PlaceHolderImg from "../../assets/images/PlaceholderImage.png";

export default function NewlyAddedStores() {
  const { newlyAddedStores } = useSelector((state) => state.Stores);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getNewlyAddedStores());
  }, [dispatch]);

  return (
    <section id="page" class="container" style={{ padding: "2% 0" }}>
      <div>
        <h4 style={{ maeginBottom: "2%" }} className="Recommended">
          Newly Added Stores
        </h4>
        <div className="row" style={{ padding: "0.5rem" }}>
          {newlyAddedStores &&
            newlyAddedStores.slice(0, 5).map((data, key) => (
              <div className="col-sm-2 " key={key}>
                <div
                  className="featured-stores"
                  style={{ backgroundColor: "white" }}
                >
                  <Link to={`/store/${data.slug}`}>
                    <img
                      src={getImageUrl(data?.image, PlaceHolderImg)}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            ))}
          <div className="col-sm-2" key={7}>
            <div
              className="featured-stores"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              <Link to={`/newlyaddedstores`} style={{ color: "purple" }}>
                Go to new stores &gt;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
