import { useState } from 'react';

export default function useSessionStorage(key, initialValue) {

  const storedValue = sessionStorage.getItem(key);
  const initial = storedValue !== null ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState(initial);

  const updateValue = (newValue) => {
    setValue(newValue);
    sessionStorage.setItem(key, JSON.stringify(newValue));
  };
  return [value, updateValue];
}