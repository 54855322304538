import React, { memo } from "react";
import MainSectionContent from "./MainSectionContent";

const MainSection = () => {

  return (
    <div>
      <div className="cursor-pointer">
        <MainSectionContent />
      </div>
    </div>
  );
};

export default memo(MainSection);
