import React, { memo, useEffect, useState } from "react";

import darkLogo from "../../assets/images/logo-dark.png";
import amzIcon from "../../assets/images/amz-icon.png";
import paypalIcon from "../../assets/images/paypal-icon.png";
import axIcon from "../../assets/images/ax-icon.png";
import mbIcon from "../../assets/images/mb-icon.png";
import mstIcon from "../../assets/images/mst-icon.png";
import mstrIcon from "../../assets/images/mstr-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryAction, getFooterAction } from "../../redux/actions";
import { Link, useLocation, useHistory } from "react-router-dom";
import { getImageUrl } from "../../utils";
import CustomLoader from "../UI/CustomLoader";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import UpdateDateMacrosInString from "../UI/UpdateDateMacrosInString";
import Macro from "../../utils/ReplaceMacro";

const Footer = () => {
  const history = useHistory();

  const {
    Category: { categoryList },
    Stores: { storesList },
  } = useSelector((state) => state);

  let activeStoresList = storesList?.filter(
    (ele) => ele.is_archived === false && ele.is_paused === false
  );

  const dispatch = useDispatch();
  const { domainData, loading: loadingWebsiteData } = useSelector(
    (state) => state.Website
  );

  // Analytics
  const location = useLocation();

  useEffect(() => {
    function set() {
      ReactGA.initialize("UA-200647590-1");
      ReactGA.pageview(location.pathname + location.search);
    }
    set();
  }, [location]);

  useEffect(() => {
    dispatch(getAllCategoryAction());
    dispatch(getFooterAction());
  }, [dispatch]);

  const getdata = (websiteData) => {
    const data = [];

    if (typeof websiteData === "string") {
      const a = websiteData?.split("</script>");
      a.forEach((ele) => {
        if (ele.includes("async")) {
          const da = ele.match(/(((https?:\/\/)|(www\.))[^\s]+)/g);
          if (da) {
            data.push({
              isAsync: true,
              src: da[0]?.slice(0, -2),
              body: null,
            });
          } 
        } else {
          data.push({
            body: ele?.slice(8),
          });
        }
      });
    }
    return data;
  };

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <footer id="footer">
      <div className="container">
        <Helmet>
          {
            // eslint-disable-next-line array-callback-return
            getdata(domainData?.header_js).map((item) => {
              if (item.isAsync) {
                return <script async src={item.src}></script>;
              }
              if (item.body) {
                return <script>{item.body}</script>;
              }
            })
          }
          {
            // eslint-disable-next-line array-callback-return
            // getdata(domainData?.footer_js).map((item) => {
            //   if (item.isAsync) {
            //     return <script async src={item.src}></script>;
            //   }
            //   if (item.body) {
            //     return <script>{item.body}</script>;
            //   }
            // })
          }
        </Helmet>
        <div className="col-sm-4">
          <div>
            {loadingWebsiteData ? (
              <CustomLoader
                customStyle={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "enter",
                }}
              />
            ) : (
              <img
                src={getImageUrl(domainData?.logo, darkLogo)}
                alt=""
                // className="img-responsive"
                // alt="#"
                style={{
                  height: "100%",
                  width: "200px",
                  background: "rgb(18,14,16)",
                  padding: "1rem",
                  borderRadius: "5px",
                }}
                className="img-responsive logo"
              />
            )}
          </div>
          <p>{domainData?.footer_about_us}</p>
          <Link to="/privacypolicy">
            <p>
              <strong>Privacy Policy</strong>
            </p>
          </Link>
        </div>
        <div className="col-sm-4">
          <h5>COMMON STORES</h5>
          <ul className="tags">
            {activeStoresList &&
              activeStoresList.length > 0 &&
              activeStoresList?.slice(0, 10).map((store) => (
                <li key={store.slug}>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/store/${store?.slug}`,
                        state: { store },
                      });
                    }}
                    className="tag"
                  >
                    <UpdateDateMacrosInString input={store?.name} />
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="col-sm-2">
          <h5>CATEGORIES</h5>
          <ul className="list-unstyled">
            {categoryList?.slice(0, 5).map((el) => (
              <li
                onClick={(e) => {
                  e.preventDefault();
                  history.push({ pathname: `/category/${el.category}` });
                }}
                className="category"
              >
                {el.category}
              </li>
            ))}
            {/* <li>All Categories ..</li> */}
          </ul>
        </div>
        <div className="col-sm-2">
          <h5>CONTACT US</h5>
          {/* <ul className="list-unstyled">
            <li style={{ whiteSpace: "pre" }}>
              <i className="fa fa-envelope"></i> {domainData?.contact?.email}
            </li>
            <li style={{ whiteSpace: "pre" }}>
              <i className="fa fa-phone"></i> {domainData?.contact?.phone}
            </li>
            <li>
              <i className="fa fa-map-marker"></i>{" "}
              {domainData?.contact?.address}
            </li>
          </ul> */}
          <div>
            <div className="row d-flex">
              <div className="col-md-2 col-sm-1">
                <i className="fa fa-envelope"></i>
              </div>
              <div className="col-md-10 col-sm-11">
                {domainData?.contact?.email}
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-2 col-sm-1">
                <i className="fa fa-phone"></i>
              </div>
              <div className="col-md-10 col-sm-11">
                {domainData?.contact?.phone}
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-2 col-sm-1">
                <i className="fa fa-map-marker"></i>
              </div>
              <div className="col-md-10 col-sm-11">
                {domainData?.contact?.address}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btmFooter">
        <div className="container">
          <div className="col-sm-7">
            <Macro input={domainData?.footer_text} />
            {/* <strong>Copyright 2015</strong>
              Kupon- deals and Coupons template made with
              <i className="ti-heart"></i>
              <strong>by Codenpixel</strong> */}
            {/* {domainData?.footer_text} */}
          </div>
          <div className="col-sm-5">
            <ul className="pay-opt pull-right list-inline list-unstyled">
              <li>
                <a href="/" title="#">
                  <img src={amzIcon} className="img-responsive" alt="" />
                </a>
              </li>
              <li>
                <a href="/" title="#">
                  <img src={paypalIcon} className="img-responsive" alt="" />
                </a>
              </li>
              <li>
                <a href="/" title="#">
                  <img src={axIcon} className="img-responsive" alt="" />
                </a>
              </li>
              <li>
                <a href="/" title="#">
                  <img src={mbIcon} className="img-responsive" alt="" />
                </a>
              </li>
              <li>
                <a href="/" title="#">
                  <img src={mstIcon} className="img-responsive" alt="" />
                </a>
              </li>
              <li>
                <a href="/" title="#">
                  <img src={mstrIcon} className="img-responsive" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* {renderHTML(websiteData)} */}
      <button
        className="btn btn-success"
        title="Scroll to top"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          backgroundColor: domainData ? domainData.header_color : "#fff",
          borderColor: "white",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1.5rem",
          borderRadius: "50%",
          padding: "10px 13px 5px 13px",
        }}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
          });
        }}
      >
        <i className="ti-arrow-circle-up"></i>
      </button>
    </footer>
  );
};

export default memo(Footer);
