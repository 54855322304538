import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
  component: Component,
  isAuthProtected,
  pageId,
  ...rest
}) => {
  
  return (
  <Route
    {...rest}
    render={props => {

      if (isAuthProtected && !localStorage.getItem('token')) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      return (
          <Component {...props} />
      );
    }}
  />
)
};

export default AppRoute;
 