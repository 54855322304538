/* eslint-disable jsx-a11y/alt-text */
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCouponsBasedOnDomainAction,
  getRecommendBannerAction,
} from "../../../redux/actions";
import { getCouponByIdAction } from "../../../redux/actions";
import { getImageUrl } from "../../../utils";
import dayjs from "dayjs";
import CentralCouponRedirect from "../../../utils/couponRedirect";
import { BASE_URL } from "../../../services/axiosInstance";

const DealSectionOne = () => {
  const dispatch = useDispatch();
  const cardClick = React.useRef();

  const { couponbaseondomainList } = useSelector(
    (state) => state.CouponBasedOnDomain
  );
  const { getRecommendBanner } = useSelector((state) => state.Coupon);

  const [showMore, setShowMore] = useState(false);
  const [useRedirectComponent, setUseRedirectComponent] = useState(false);
  const handleShow = () => {
    setShowMore(!showMore);
  };

  const [displayWidth, setDisplayWidth] = useState(0);

  useEffect(() => {
    setDisplayWidth(window.innerWidth);
  });

  useEffect(() => {
    dispatch(getRecommendBannerAction({ dimension: "300 * 250" }));
  }, [dispatch]);

  useEffect(() => {
    function fetch() {
      dispatch(getCouponsBasedOnDomainAction());
    }
    fetch();
  }, [dispatch]);

  const countDownTimer = (expDate) => {
    // Get today's date and time
    let now = new Date().getTime();
    let distance = new Date(expDate).getTime() - now;

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    if (distance < 0) {
      return <p>Expired</p>;
    } else {
      return (
        <p>
          {" "}
          <i class="ti-timer"></i> {days} <b>d</b> {hours} <b>hr</b> {minutes}{" "}
          <b>min</b>
        </p>
      );
    }
  };

  return (
    <section id="page" style={{ marginTop: "3%" }}>
      {useRedirectComponent && (
        <CentralCouponRedirect
          e={useRedirectComponent.e}
          coupon={useRedirectComponent.o}
          setUseRedirectComponent={setUseRedirectComponent}
        ></CentralCouponRedirect>
      )}
      <div class="twelve" id="newly-head " style={{ marginBottom: "2%" }}>
        <h1 style={{ paddingRight: "2%" }}>Recommeded Deals </h1>
        {console.log("displayWidth>>>>", displayWidth, displayWidth > 768)}
      </div>

      <div
        id={displayWidth > 768 ? "most-trending-home3" : "deals-cards-home3"}
      >
        {couponbaseondomainList &&
          couponbaseondomainList.length > 0 &&
          couponbaseondomainList.slice(0, showMore ? 18 : 11).map((o, i) => {
            return (
              <>
                {i === 3 && (
                  <div
                    class="card cursor-pointer banners-deals-h3"
                    id="banner-card-n-3"
                  >
                    <div class="card-header">
                      <img
                        className="h3-deals-banner"
                        src={`${BASE_URL}/${getRecommendBanner[0]?.image_upload}`}
                        onClick={() =>
                          window.open(
                            getRecommendBanner[0]?.redirect_url,
                            "_blank"
                          )
                        }
                        alt=""
                      />
                    </div>
                    <div class="card-body"> </div>
                  </div>
                )}
                {i === 6 && (
                  <>
                    <div
                      class="card cursor-pointer banners-deals-h3"
                      id="banner-card-n-3"
                    >
                      <div class="card-header">
                        <img
                          className="h3-deals-banner"
                          src={`${BASE_URL}/${getRecommendBanner[1]?.image_upload}`}
                          alt=""
                          onClick={() =>
                            window.open(
                              getRecommendBanner[1]?.redirect_url,
                              "_blank"
                            )
                          }
                        />
                      </div>
                      <div class="card-body"> </div>
                    </div>
                  </>
                )}

                {i !== 6 && (
                  <div
                    class="card h3-cards-deals"
                    onClick={() => cardClick.current.click()}
                  >
                    <div class="card-header">
                      <img
                        id="home3-deas-card-img"
                        style={{ maxHeight: "250px", objectFit: "contain" }}
                        src={getImageUrl(o?.store?.image)}
                      />
                    </div>
                    <div class="card-body">
                      {o?.category !== "" && (
                        <span
                          class="tag2 tag2-purple"
                          style={{
                            borderRadius: "1px",
                            margin: "0",
                            backgroundColor: "#fb5abc",
                          }}
                        >
                          <i class="ti-tag"></i>
                          {o?.store?.name
                            ? o?.store?.name
                            : o?.category?.length > 28
                            ? o?.category?.substring(0, 28) + "..."
                            : o?.category}
                        </span>
                      )}
                      <strong>{o?.name?.slice(0, 50)}</strong>
                    </div>
                    <footer
                      class="info_bar clearfix"
                      style={{ margin: "0", padding: "2%", height: "80px" }}
                    >
                      <ul
                        class="unstyled list-inline row"
                        style={{ padding: "5%" }}
                      >
                        <li
                          class="time col-sm-7 col-xs-6 col-lg-8"
                          style={{ margin: "0", padding: "0" }}
                        >
                          <p
                            style={{
                              margin: "0",
                              padding: "0",
                              alignItems: "left",
                            }}
                            className="mobile-hidden-h3"
                          >
                            Expiry :{" "}
                            {dayjs(o.expiry_date).format("DD MMMM YYYY")}
                          </p>
                          <p
                            style={{ margin: 0, alignItems: "left" }}
                            className="mobile-hidden-h3"
                          >
                            {countDownTimer(o.expiry_date)}
                          </p>
                        </li>

                        <button
                          id="show-code-3"
                          ref={cardClick}
                          style={{ backgroundColor: "rgb(184, 9, 121)" }}
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   dispatch(
                          //     getCouponByIdAction({ coupon_id: o?._id })
                          //   );
                          //   window.open(
                          //     `/couponsredirect/coupon/${
                          //       o?.store?.slug?.replace(" ", "") ||
                          //       o?.store?.name?.replace(" ", "")
                          //     }/${o?._id}`,
                          //     "_blank"
                          //   );
                          // }}
                            
                    onClick={(e) => {
                      e.preventDefault();
                      setUseRedirectComponent({ e, o });
                    }}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Show code
                        </button>
                      </ul>
                    </footer>
                  </div>
                )}
              </>
            );
          })}
      </div>
      <div
        style={{
          textAlign: "center",
          padding: "2vh 0 1vh 0",
          fontSize: ".8rem",
        }}
      >
        <span
          onClick={handleShow}
          style={{
            padding: "5px",
            borderRadius: "5px",
            cursor: "pointer",
            border: "2px solid #b80979",
            fontWeight: "600",
            color: "#b80979",
            fontSize: "15px",
          }}
        >
          {showMore ? "Show less" : "Show more"}{" "}
        </span>
      </div>
    </section>
  );
};

export default memo(DealSectionOne);
