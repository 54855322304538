import React, {
  useState,
} from "react";
import {  useSelector } from "react-redux";
import CustomLoader from "../../UI/CustomLoader";
import MainSectionSidebarItem from "../MainSectionSidebarItem/MainSectionSidebarItem";

const MainSectionSidebar = () => {
  const [searchText, setSearchText] = useState('')
  const { categoryList, loading} = useSelector(
    (state) => state.Category
  );

  return (
    <div  className="col-sm-3 col-md-8 sidebar home-2-catgories" id="categories-n" >
      <div className="bg-white shadow">
        <div className="widget-menu">
          <div className="cat-text">
            <input
              className="form-control"
              placeholder="Search Category"
              type="text"
              value={searchText}
              onChange={(e)=>{
                setSearchText(e.target.value)
              }}
            />
          </div>
          <ul className="nav sidebar-nav custom-scrollbar2"  style={{ height:'331px',overflowY:"auto"}}>

            {
              loading ? 
              <CustomLoader />
              : categoryList && categoryList.length > 0 &&  categoryList.filter(el=>(el.category.toLowerCase().includes(searchText.toLowerCase()))).map(el=>(
                <MainSectionSidebarItem category={el.category} count={el.count}/>
              ))
            }
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainSectionSidebar;
