import React, { memo } from 'react'
import DealSectionOne from './DealSectionOne'
import MainSection from './MainSection'
import NewlyAddedStores from './NewlyAddedStores'
import MostTrending from './MostTrending'
import Category from "./MainSectionSidebar";
import HBanner from "./Banners3";
import {getEndUserAction} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";


const Home = () => {

  const dispatch = useDispatch();


  const { getEndUser } = useSelector((state) => state.Coupon);


   React.useEffect(() => {
    dispatch(getEndUserAction());
  }, [dispatch]);

  
  return (
    <div className="container" style={{background:"#FAEBD750", paddingTop:"1px"}}>
      <MainSection />
      <DealSectionOne />
      <div>
      </div>
      <NewlyAddedStores />
      <HBanner getEndUser={getEndUser} />
      <Category />
      <MostTrending  />
    </div>
  )
}

export default memo(Home);
