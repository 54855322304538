import {
  SET_REGISTER_LOADING,
  REGISTER_SUCCESSFUL,
  REGISTER_FAILED,
} from "./actionTypes";

const initialState = {
  error: null,
  errorMessage: "",
  loading: false,
  token: null,
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGISTER_LOADING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REGISTER_SUCCESSFUL:
      if(typeof action.payload === 'object'){
        sessionStorage.setItem("token", action.payload.token);
      } else {
        sessionStorage.setItem("token", action.payload);
      }
      state = {
        ...state,
        loading: false,
        token: typeof action.payload === 'object' ? action.payload.token : action.payload,
        error: false,
        errorMessage: "",
      };
      break;
    case REGISTER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default register;
