import {useEffect} from "react";
import DefaultHome from "./Home";
import HomeTwo from "./home2/Home2";
import HomeThree from "./home3/Home2";
import HomeFour from "./home4/Home2";
import HomeFive from "./home5/Home2";
//import SkeletonLoader from "./SkeletonLoader";
import {useSelector, useDispatch} from "react-redux";
import {getAdBannerByDomainAction} from "../../redux/website/actions";
import CustomLoader from "../UI/CustomLoader";

export default function HomeChanger() {

  const dispatch = useDispatch();
  
  const { domainData } = useSelector(
    (state) => state.Website
  );

  useEffect(()=>{
    if(!(domainData?.header_color)){
    dispatch(getAdBannerByDomainAction());
  }
  }, [dispatch]);

if(!domainData){
  return(
    <CustomLoader />
  )
}else{
  switch (domainData.home_page) {
    case "Home Page One":
      return <HomeTwo />
    case "Home Page Two":
      return <HomeThree />;
    case "Home Page Three":
      return <HomeFour />;
      case "Home Page Four":
        return <HomeFour />;
        case "Home Page Five":
          return <HomeFive />;
    default:
      return <DefaultHome />;
  }
}
}
