import * as actionTypes from "./actionTypes";

export const getAllCategoryAction = () => ({
  type: actionTypes.GET_ALL_CATEGORY,
});

export const getCouponsByCategoryAction = (payload,meta) => ({
  type: actionTypes.GET_COUPONS_BY_CATEGORY,
  payload,
  meta
})