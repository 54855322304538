import React, { memo, useEffect, useState } from "react";

import darkLogo from "../../../assets/images/logo-dark.png";

import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryAction, getFooterAction } from "../../../redux/actions";
import { useLocation, Link } from "react-router-dom";
import { getImageUrl } from "../../../utils";
import ReactGA from "react-ga";
import Macro from "../../../utils/ReplaceMacro";

const Footer = ({ history }) => {

         const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);
  const {
    Category: { categoryList },
  } = useSelector((state) => state);


  const dispatch = useDispatch();
  const { domainData } = useSelector(
    (state) => state.Website
  );

  // Analytics
  const location = useLocation();

  useEffect(() => {
    function set() {
      ReactGA.initialize("UA-200647590-1");
      ReactGA.pageview(location.pathname + location.search);
    }
    set();
  }, [location]);

  useEffect(() => {
    function fetch() {
      dispatch(getAllCategoryAction());
      dispatch(getFooterAction());
    }
    fetch();
  }, [dispatch]);

  return (
    <div className="footer"  style={{ backgroundColor: "black", paddingLeft:"7%" }}>
  <div className="inner-footer">
    <div id="foot-logo-h3" className="footer-items">
      <img
                src={getImageUrl(domainData?.logo, darkLogo)}
                alt=""
                style={{
                  height: "50%",
                  width: "160px",
                  border: "3px solid #eeee10",
                  padding: "1rem",
                  borderRadius: "5px",
                  marginTop:"10%",
                  marginLeft:"7%"
                }}
                className="img-responsive logo"
                id="h2-foot-logo"
              />
          <p style={{marginTop:"10px"}}>{domainData?.footer_about_us}</p>
    </div>
    <div className="footer-items">
      <h3 style={{color:"white"}}>Categories</h3>
      <div className="border1" style={{ background:"#eeee10"}} /> {/*for the underline */}
      <ul className="footer-ul">
            {categoryList?.slice(0, 5).map((el) => (
              <li
              onClick={()=>{
                history.push(`/category/${el.category}`);
              }}
                className="category"
              >
                <Link to={`/category/${el.category}`}>
                {el.category}
                </Link>
              </li>
            ))}
            {/* <li>All Categories ..</li> */}
      </ul>
    </div>
    {/*  for contact us info */}
    <div className="footer-items">
      <h3 style={{color:"white"}}>Contact us</h3>
      <div className="border1" style={{ background:"#eeee10"}}  />
      <ul className="footer-ul">
        <li>
          <i className="fa fa-map-marker" aria-hidden="true" />
         {domainData?.contact?.address}
        </li>
        <li>
          <i className="fa fa-phone" aria-hidden="true" />
          {domainData?.contact?.phone}
        </li>
        <li>
          <i className="fa fa-envelope" aria-hidden="true" />
          {domainData?.contact?.email}
        </li>
      </ul>
    </div>
  </div>
  {/*   Footer Bottom start  */}
  <div  style={{ backgroundColor: "#000909", color:"yellow" }} className="footer-bottom"><Macro input={domainData?.footer_text} /></div>
    <button
      id="show-code"
        className="btn btn-success"
        title="Scroll to top"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "2px",
          backgroundColor: "#eeee10",
          border: "1px solid white",
          borderColor: "white",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize:"1.5rem",
          paddingBottom:"40px",
          padddingTop:"10px",
          color:"black"
        }}
        onClick={() => {
        window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
      }}
      >
      <i class="fa fa-arrow-up"></i>
      </button>
</div>

  );
};

export default memo(Footer);
