import React, { memo, useEffect, useMemo, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import Carousel from "nuka-carousel";
import { getImageUrl } from "../../../utils";

const MainSectionContent = () => {
  
  const { adBanners } = useSelector((state) => state.Website);

  const [items2, setItems2] = useState([]);


  const items = useMemo(() => {
    let arr = [];
    adBanners?.forEach((i) => {
      if (i.banner_dimension === "1480 * 630") {
        let obj = {
          src: getImageUrl(i.image_upload),
          url: i.image_url,
          altText: "ad",
        };
        arr.push(obj);
      }
    });
    return arr;
  }, [adBanners]);

  useEffect(()=>{
    setItems2(()=>{
      let temp = [];
      for(let i = 0; i < items.length; i = i+2){
        temp.push([items[i], items[i+1]])
      };
      return temp;
    })
  }, [items])

  return (
    <div>
      <Carousel>
        {items2.map(arr=>{
          return (
        <div id="h5-banner-grid">  
          {arr[0] && (
            <img
              key={arr[0].url}
              className="cursor-pointer"
              id="carousel-img-5"
              src={arr[0].url || arr[0].src}
              alt={arr[0].altText}
              onClick={() => window.open(arr[0]?.redirect_url, "_blank")}
            />
          )}
          {arr[1] && (
            <img
              key={arr[1].url}
              className="cursor-pointer"
              id="carousel-img-5"
              src={arr[1].url || arr[1].src}
              alt={arr[1].altText}
              onClick={() => window.open(arr[1]?.redirect_url, "_blank")}
            />
          )}
        </div>
         )} )}
      </Carousel>
    </div>
  );
};

export default memo(MainSectionContent);
