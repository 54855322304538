import { fork, put, all, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import API from "../../services/axiosInstance";

function* postNewsletterSaga({ payload }) {
  try {
    yield put({ type: actionTypes.POST_NEWSLETTER_ACTION_LOADING });
    const { data } = yield API.post(
      "/end-user/newsletter/email-subscribe",
      payload
    );
    if (data.sucess) {
      yield put({
        type: actionTypes.POST_NEWSLETTER_ACTION_SUCCESS,
        successMessage: data.message,
        sucess: data.sucess,
      });
    } else {
      yield put({
        type: actionTypes.POST_NEWSLETTER_ACTION_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    yield put({
      type: actionTypes.POST_NEWSLETTER_ACTION_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* resetNewsletterStateSaga(){
  console.log("fvr6e 64e5 e6 e6 e86 e64e 5 e5 e5y 5e5")
  try{
    yield put({type: actionTypes.RESET_NEWSLETTER_STATE_SUCCESS})
  } catch {
    console.log("reset newsletter state saga catch")
  }
}


  export function* watchResetNewsletterStateSaga() {
    yield takeLatest(actionTypes.RESET_NEWSLETTER_STATE, resetNewsletterStateSaga)
  }

    export function* watchPostNewsletterSaga () {
    yield takeLatest(actionTypes.POST_NEWSLETTER_ACTION, postNewsletterSaga)
  }


  function* NewsletterSaga() {
    yield all([
        fork(watchResetNewsletterStateSaga),
        fork(watchPostNewsletterSaga),
    ]);
}

export default NewsletterSaga;