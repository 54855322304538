import DefaultFooter from "../Footer/Footer";
import FooterTwo from "./home2/Footer";
import FooterThree from "./home3/NewFooter";
import FooterFour from "./home4/NewFooter";
import FooterFive from "./home5/NewFooter";
import {useSelector} from "react-redux";

export default function HomeChanger() {
  const { domainData } = useSelector(
    (state) => state.Website
  );
  
  if(window.location.pathname.includes("mr")){
    return <div />;
  }else{
  switch (domainData?.home_page) {
    case "Home Page One":
      return <FooterTwo />;
    case "Home Page Two":
      return <FooterThree />;
    case "Home Page Three":
      return <FooterFour />;
      case "Home Page Four":
        return <FooterFive />;
    default:
      return <DefaultFooter />;
  }
}
}
