import react, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getCouponUrlAction } from "../redux/couponUrl/actions";

export default function CentralCouponRedirect({
  e,
  coupon,
  setUseRedirectComponent,
}) {
  // need to send the coupon details in as props
  const history = useHistory();
  const dispatch = useDispatch();
  const { link } = useSelector((state) => state.CouponUrl);
  // const { selectedStore } = useSelector((state) => state.Stores); //need to arrange this data from somewhere

  const [selectedStore, setSelectedStore] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [redirect2, setRedirect2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newTab, setNewTab] = useState(false);

  const [second, setSecond] = useState(false);

  const handleRedirect = () => {
    e.stopPropagation();

    let lsLink =
      !coupon.expiry_date || coupon.expiry_date > Date.now()
        ? coupon?.finalLink || coupon?.link
        : coupon?.link || "";

    function withReferLogic() {
      history.push(
        `/couponzr/store/${selectedStore?.slug?.replace(
          " ",
          ""
        )}/coupon/${selectedStore?.slug?.replace(" ", "")}/${coupon?._id}`
      );

      window.open(coupon?.finalLink);
      setUseRedirectComponent(false);
      return;
    }

    if (
      selectedStore?.desktop_redirection_type === "zero-redirect-with-refer"
    ) {
      return withReferLogic();
    }

    setLoading(true);

    dispatch(
      getCouponUrlAction({
        finalLink: lsLink,
      })
    );

    if (link) {
      setSecond(true); //to run useEffect even when link has not changed
    }

    if (
      selectedStore?.desktop_redirection_type === "zero-redirect-without-refer"
    ) {
      setRedirect(true);
    } else if (
      selectedStore?.desktop_redirection_type === "zero-redirect-with-refer"
    ) {
      setRedirect2(true);
    } else {
      localStorage.setItem(
        "redirection_type",
        selectedStore?.desktop_redirection_type
      );
      localStorage.setItem("redirection_type_store", "Redirection");

      localStorage.setItem("link", lsLink || "");

      if (history.location.pathname.split("/").length > 2) {
        let routePath = `/category/${
          history.location.pathname.split("/")[2]
        }/coupon/${coupon?.store_id?.name.replace(" ", "")}/${coupon?._id}`;
        // history.push({pathname:`${routePath}`});
        window.open(
          `/couponsredirect/category/${
            history.location.pathname.split("/")[2]
          }/coupon/${coupon?.store_id?.name.replace(" ", "")}/${coupon?._id}`,
          "_blank"
        );
        setUseRedirectComponent(false);
      } else {
        let routePath = `/coupon/${coupon?.store_id?.slug?.replace(" ", "")}/${
          coupon?._id
        }`;
        window.open(
          `/couponsredirect/coupon/${coupon?.store_id?.slug?.replace(
            " ",
            ""
          )}/${coupon?._id}`,
          "_blank"
        );
        // history.push({pathname:`${routePath}`});
        setUseRedirectComponent(false);
      }
    }
  };

  useEffect(() => {
    handleRedirect();
  }, []);

  useEffect(() => {
    setSelectedStore(coupon?.store_id);
  }, [coupon]);

  useEffect(() => {
    // localStorage.setItem("link", link);

    if (redirect) {
      setLoading(false);
      setNewTab(!newTab);
    }
    if (redirect2) {
      setLoading(false);
      setNewTab(!newTab);
    }
  }, [link, newTab, redirect, redirect2, second]);

  useEffect(() => {
    if (link && redirect) {
      setRedirect(false);

      window.open(`/mr/`, "_blank");
      setUseRedirectComponent(false);
    }
    if (link && redirect2) {
      history.push(
        `/couponzr/store/${selectedStore?.slug?.replace(
          " ",
          ""
        )}/coupon/${selectedStore?.slug?.replace(" ", "")}/${coupon?._id}`
      );
      setRedirect2(false);
      window.open(link, "_blank");
      setUseRedirectComponent(false);
    }
  }, [link, newTab, redirect, redirect2]);

  return <></>;
}
