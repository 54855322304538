/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import { getTopVisitedStores } from "../../../redux/stores/actions";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../../utils";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.png";
import "../../../assets/css/home2.css";

export default function MostTrending() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTopVisitedStores());
  }, [dispatch]);

  const addDefaultSrc = (e) => {
    e.target.src = PlaceHolderImg;
  };

  const { topRatedStores } = useSelector((state) => state.Stores);
  return (
    <section id="page-mt mt-3-m mt-h3" className="container">
      <div id="mts-h3-head" className="twelve" style={{display:"flex", justifyContent:"center"}}>
        <h1>Most trending stores</h1>
      </div>

      <div className="grid-container-mt3">
        {topRatedStores &&
          topRatedStores.slice(0, 8).map((data, key) => (
            <Link to={`/store/${data.store_details.slug}`}>
              <div
                className={`card-h3 card-${key}`}
                style={{ position: "relative" }}
              >
                <img
                  onError={(e) => addDefaultSrc(e)}
                  src={getImageUrl(data?.store_details.image, PlaceHolderImg)}
                  style={{
                    height: "75px",
                    width: "100px",
                    objectFit: "cover",
                    position: "absolute",
                    top: ".1px",
                    left: "15px",
                    borderRadius: "0 0 10px 10px",
                  }}
                  alt='adb'
                /> 
                <p className="card__exit">
                </p>
                <h2 className="card__title">
                  {data?.store_details?.slug.substring(0, 20)} : View all
                  coupons
                </h2>
                <p className="card__apply">
                  <a className="card__link" href="#">
                    Visit Now
                  </a>
                </p>
              </div>
            </Link>
          ))}
      </div>
    </section>
  );
}
