import { put, takeLatest } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import API from "../../services/axiosInstance";

function* sendContactDetailsSaga({payload:{domain, ...rest} }) {
  try {
    yield put({ type: actionTypes.SET_CONTACT_LOADING });
    const { data } = yield API.post("/api/end-user/mail/sendMail", rest, {
      headers: {
        "domain-name": domain,
      },
    });

    if (data.success) {
      yield put({
        type: actionTypes.SEND_CONTACT_DETAILS_SUCCESS,
        message: data.message,
      });
    } else {
      yield put({
        type: actionTypes.SEND_CONTACT_DETAILS_FAILURE,
        errorMessage: data.errorMessage,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SEND_CONTACT_DETAILS_FAILURE,
      errorMessage: "Unable to send information",
    });
  }
}

export function* ContactSaga() {
  yield takeLatest(actionTypes.SEND_CONTACT_DETAILS, sendContactDetailsSaga);
}

export default ContactSaga;
