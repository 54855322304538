import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../services/axiosInstance";
import styled from "styled-components";
import { Link } from "react-router-dom";

const MainSectionContent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { domainData } = useSelector((state) => state.Website);
  const { storesList } = useSelector((state) => state.Stores);

  const Div = styled.div`
    // height: 434px;
    max-width: 100%;
    margin-top: 1rem;
    // .slick-slider {
    //   height: 440px;
    // }
    .slick-slide > div {
      height: 419px;
    }

    .slick-dots {
      bottom: -10px;
    }

    .slick-prev::before,
    .slick-next::before {
      color: ${domainData?.header_color ? domainData?.header_color : "orange"};
    }

    .banner-carousel--item {
      height: 100%;
      background-color: #f7f7f7;
    }

    .banner-carousel--item > div {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  `;

  return (
    <>
      <Div className="col-sm-12 col-md-9 ">
        <Slider {...settings}>
          {domainData &&
            domainData?.banners_v2?.map((ele, index) => (
              <div key={index} className="banner-carousel--item">
                {ele.url ? (
                  <a href={ele.url} target="_blank" rel="noreferrer">
                    <div
                      className="sliderImg"
                      style={{
                        background: `url(${encodeURI(
                          BASE_URL + "/" + ele?.image
                        )}) center/cover no-repeat`,
                        height: "100%",
                      }}
                    ></div>
                  </a>
                ) : ele.storeId === null ||
                  ele.storeId === undefined ||
                  ele.storeId === "" ? (
                  <div
                    className="sliderImg"
                    style={{
                      background: `url(${encodeURI(
                        BASE_URL + "/" + ele?.image
                      )}) center/cover no-repeat`,
                      height: "100%",
                    }}
                  ></div>
                ) : (
                  <Link
                    to={`/store/${
                      storesList?.filter((el) => el._id === ele.storeId)[0]
                        ?.slug
                    }`}
                  >
                    <div
                      className="sliderImg"
                      style={{
                        background: `url(${encodeURI(
                          BASE_URL + "/" + ele?.image
                        )}) center/cover no-repeat`,
                        height: "100%",
                      }}
                    ></div>
                  </Link>
                )}
              </div>
            ))}
        </Slider>
      </Div>
    </>
  );
};

export default MainSectionContent;
