import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/home3.css";
import {
  getGoogleVerificationCodeByDomainAction,
  getStoresBasedOnDomainAction,
  getWebsiteDataByDomainAction,

} from "../../../redux/actions";
import { getCouponListByNameAction } from "../../../redux/actions";
import {getStoresByNameAction} from "../../../redux/stores/actions";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../../utils";




const Header = () => {
  const dispatch = useDispatch();
 
  const { domainData} = useSelector(
    (state) => state.Website
  );

  useEffect(() => {
    function fetch() {
      dispatch(getStoresBasedOnDomainAction());
    }
    fetch();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGoogleVerificationCodeByDomainAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.href])


  const[searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = () => {
    dispatch(getCouponListByNameAction({ name: searchValue }));
    dispatch(getStoresByNameAction({ name: searchValue }));
    enterSearch.current.click();
  }

  const enterSearch = React.useRef()

  return (
    <>
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{padding:"0", backgroundColor:"#b80979", width:"100vw"}}>
    <div id="nav-strip" style={{backgroundColor:"#88085c", height:"19px" ,fontWeight:"500", fontSize:'.7rem'}}>
    <span style={{float: "right", color:"#ffeaf6", marginRight:"20%", cursor:'pointer'}}>
    <i className="fa fa-flash" style={{color:"#ffeaf6", paddingRight:"3px"}}></i>
    Amazon Lightening Deal
    </span>
    <span style={{float: "right", color:"#ffeaf6", marginRight:"2%", cursor:'pointer'}}>
    <i className="fa fa-square" style={{color:"#ffeaf6", paddingRight:"3px"}}></i>
    Advertise with us
    </span>
    <span style={{float: "right", color:"#ffeaf6", marginRight:"2%", cursor:'pointer'}}>
    <i className="fa fa-money" style={{color:"#ffeaf6", paddingRight:"3px"}}></i>
    100% Cashback
    </span>
    </div>
      <div className="container" style={{paddingTop:"4px"}}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={()=> setIsOpen(!isOpen)}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {domainData?.logo ? (
                          <img
                            src={getImageUrl(domainData?.logo, logo)}
                            alt=""
                            className="img-responsive logo-nav-h3"
                            id="logo-nav"
                            style={{
                              objectFit: "contain",
                            }}
                          /> ) : " "}
                          {" "}

        </div>

        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="bs-example-navbar-collapse-1"
        >
        <input id="navbar-3" type="text" className="searchTerm-3" placeholder="What are you looking for?" 
        style={{color: domainData?.header_color, borderColor: "#b80979", background:"#ffeaf6", borderRadius:"4px 0 0 4px"}}
        onChange={(e)=>setSearchValue(e.target.value)} 
    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSearch()
                        } }}
    value={searchValue} />

    <Link to="/searchpage">
      <button  ref={enterSearch}  className="searchButton" 
      onClick={handleSearch} style={{height:"38px", paddingBottom:"5px", paddingTop:"2px", 
      background: "#ffeaf6", borderColor: "#ffeaf6", color:"#b80979"}}
      >
        <i className="fa fa-search"></i>
     </button>
     </Link>
     <button id="navbar-en-3" style={{color:"#b80979", background: `radial-gradient( 100% 100% at 100% 0%, #ffeaf6 0%, #ffeaf6  110% )`, boxShadow:"1px solid #e90a96", width:"180px", padding:"0"}}><a href="/categories">All categories</a></button>
          <ul className="nav navbar-nav navbar-right" id="nav-ul-n">
  <li>
    <Link to="/store" className="page-scroll" style={{color: domainData?.header_color}}>
      Store
    </Link>
  </li>
  <li>
    <Link to="/aboutus" className="page-scroll" style={{color: domainData?.header_color}}>
      About us
    </Link>
  </li>
  <li>
    <Link to="/categories" className="page-scroll" style={{color: domainData?.header_color}}>
      Categories
    </Link>
  </li>
  <li>
    <Link to="/contactus" className="page-scroll" style={{color: domainData?.header_color}}>
      Contact us
    </Link>
  </li>
</ul>
        </div>
      </div>


      <div className="container" id="mobile-hidden-nav" style={{backgroundColor:"#b80979", marginTop:".5%"}}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={()=> setIsOpen(!isOpen)}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
                          {" "}

        </div>

        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="bs-example-navbar-collapse-1"
        >

<ul className="nav navbar-nav navbar-right" style={{marginRight:"17%",}}>
  <li>
    <Link to="/" className="page-scroll" style={{color: "#ffeaf6", marginRight:"5.1vw"}}>
      Home
    </Link>
  </li>
  <li>
    <Link to="/store" className="page-scroll" style={{color: "#ffeaf6", marginRight:"5.1vw"}}>
      Stores
    </Link>
  </li>
  <li>
    <Link to="/aboutus" className="page-scroll" style={{color: "#ffeaf6", marginRight:"5.1vw"}}>
      About us
    </Link>
  </li>
  <li>
    <Link to="/categories" className="page-scroll" style={{color: "#ffeaf6", marginRight:"5.1vw"}}>
      Categories
    </Link>
  </li>
  <li>
    <Link to="/contactus" className="page-scroll" style={{color: "#ffeaf6", marginRight:"5.1vw"}}>
      Contact us
    </Link>
  </li>
</ul>
        </div>
      </div>
    </nav>

    {/*duplicate content so it doesen't overlap pages*/}
    <nav id="menu" className="navbar navbar-default navbar-fixed-top nav-margin" style={{position:"static", margin:"0", paddingTop:"50px"}}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={()=> setIsOpen(!isOpen)}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {domainData?.logo ? (
                          <img
                            src={getImageUrl(domainData?.logo, logo)}
                            alt=""
                            className="img-responsive"
                            id="logo-nav"
                            style={{
                              objectFit: "contain",
                            }}
                          /> ) : " "}
                          {" "}

        </div>

        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="bs-example-navbar-collapse-1"
        >
        <input type="text" className="searchTerm" placeholder="What are you looking for?" style={{height:"35px", color: domainData?.header_color, borderColor: domainData?.header_color}} />
      <button type="submit" className="searchButton"  style={{height:"36px", paddingBottom:"3px", backgroundColor: domainData?.header_color, borderColor: domainData?.header_color}}>
        <i className="fa fa-search"></i>
     </button>
     <button id="navbar-en" style={{backgroundColor: domainData?.header_color}}><a href="/store">All stores</a></button>
          <ul className="nav navbar-nav navbar-right" id="nav-ul-n">
  <li>
    <Link to="/store" className="page-scroll" style={{color: domainData?.header_color}}>
      Store
    </Link>
  </li>
  <li>
    <Link to="/aboutus" className="page-scroll" style={{color: domainData?.header_color}}>
      About us
    </Link>
  </li>
  <li>
    <Link to="/categories" className="page-scroll" style={{color: domainData?.header_color}}>
      Categories
    </Link>
  </li>
  <li>
    <Link to="/contactus" className="page-scroll" style={{color: domainData?.header_color}}>
      Contact us
    </Link>
  </li>
</ul>
        </div>
      </div>


      <div className="container" id="mobile-hidden-nav" style={{backgroundColor:"#f7f8fa", marginTop:".5%"}}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={()=> setIsOpen(!isOpen)}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
                          {" "}

        </div>

        
      </div>
    </nav>
    </>
  );
};

export default Header;