import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postNewsletteraction,
  resetNewsletterStateAction,
} from "../../../redux/Newsletter/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLoader from "../../UI/CustomLoader";
import { getRecommendBannerActionTwo } from "../../../redux/actions";
import { BASE_URL } from "../../../services/axiosInstance";

export default function NewsLetter() {
  const { successMessage, loading, errorMessage } = useSelector(
    (state) => state.Newsletter
  );
  const enterSearch = React.useRef();
  const dispatch = useDispatch();

  const [email_id, setEmail] = useState("");

  const validate = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleNewsletter = () => {
    if (!validate(email_id)) {
      toast.error("Please provide a valid email address.");
      return;
    }
    dispatch(postNewsletteraction({ email_id }));
  };

  const { getRecommendBanner } = useSelector((state) => state.Website);

  const HorizontalBanner = getRecommendBanner?.filter(function (el) {
    return el.banner_dimension === "300 * 250";
  });

  React.useEffect(() => {
    dispatch(getRecommendBannerActionTwo({ dimension: "300 * 250" }));
  }, [dispatch]);

  React.useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(resetNewsletterStateAction());
    }

    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(resetNewsletterStateAction());
    }
  }, [dispatch, errorMessage, loading, successMessage]);

  return (
    <>
      {loading && <CustomLoader />}
      <div className="container h4-newsletter">
        <div id="h4-news-sqb">
          {HorizontalBanner && (
            <img
              id="h4-sqare-baner"
              className="h4-news-max-height"
              style={{
                width: "100%",
                objectFit: "contian",
                marginBottom: "10px",
              }}
              alt=""
              onClick={() =>
                window.open(HorizontalBanner[1]?.redirect_url, "_blank")
              }
              src={
                HorizontalBanner[1]?.image_url ||
                `${BASE_URL}/${HorizontalBanner[1]?.image_upload}`
              }
            />
          )}
          <div
            id="h4-newsletter"
            style={{
              marginBottom: "10px",
              height: "200px",
              padding: "5px 0 8px 0",
              backgroundColor: "#EF4444",
              textAlign: "center",
              border: "2px dotted white",
              position: "relative",
            }}
          >
            <div
              style={{ position: "absolute", top: "1px", left: "1px" }}
              className="hide-below768"
            >
              <img
                src="https://cdn.pixabay.com/photo/2016/09/04/17/46/news-1644696__340.png"
                alt=""
                width="50"
                height="25"
              />
            </div>
            <div>
              <div>
                <i
                  className="fa fa-envelope newsletter-icon-h4"
                  style={{
                    color: "white",
                    fontSize: "30px",
                    boxShadow: "5px solid black",
                  }}
                ></i>
              </div>
              <p
                style={{ color: "white", display: "inline", fontWeight: "500" }}
              >
                Sign up for the weekly email newsletter with the best coupons.
              </p>
              <div
                className="input-group"
                style={{
                  whiteSpace: "nowrap",
                  margin: "1% auto",
                  paddingRight: "8%",
                }}
              >
                <input
                  id="h4-subscribe-input"
                  required
                  type="email"
                  className="form-control shadow-none"
                  placeholder="your email.."
                  style={{
                    borderRadius: "3.5px 0 0 3.5px",
                    height: "35px",
                    border: "2px dotted white",
                  }}
                  value={email_id}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div
                  className="input-group-append"
                  style={{ display: "inline-block" }}
                >
                  <button
                    ref={enterSearch}
                    onClick={() => handleNewsletter()}
                    style={{
                      height: "35px",
                      backgroundColor: "#DC2626",
                      borderLeft: "none",
                      border: "3.5px solid #DC2626",
                      borderRadius: "0 4px 4px 0",
                      fontWeight: "bold",
                      display: "inline",
                      fontSize: "13px",
                    }}
                    class="btn btn-danger shadow-none"
                    type="button"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
              <p
                style={{ color: "white", display: "inline", fontWeight: "500" }}
              >
                We will never share your email with a third party.
              </p>

              <ToastContainer position="top-right" />
            </div>
            <div
              style={{ position: "absolute", bottom: "1px", left: "1px" }}
              className="hide-below768"
            >
              <img
                src="https://cdn.pixabay.com/photo/2016/09/04/17/46/news-1644696__340.png"
                alt=""
                width="50"
                height="25"
              />
            </div>
            <div
              style={{ position: "absolute", top: "1px", right: "1px" }}
              className="hide-below768"
            >
              <img
                src="https://cdn.pixabay.com/photo/2016/09/04/17/46/news-1644696__340.png"
                alt=""
                width="50"
                height="25"
              />
            </div>
            <div
              style={{ position: "absolute", bottom: "1px", right: "1px" }}
              className="hide-below768"
            >
              <img
                src="https://cdn.pixabay.com/photo/2016/09/04/17/46/news-1644696__340.png"
                alt=""
                width="50"
                height="25"
              />
            </div>{" "}
          </div>
          {HorizontalBanner && (
            <img
              id="h4-sqare-baner"
              className="h4-news-max-height"
              style={{ width: "100%", objectFit: "contian" }}
              alt=""
              onClick={() =>
                window.open(HorizontalBanner[0]?.redirect_url, "_blank")
              }
              src={
                HorizontalBanner[0]?.image_url ||
                `${BASE_URL}/${HorizontalBanner[0]?.image_upload}`
              }
            />
          )}
        </div>
      </div>
    </>
  );
}
