import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCouponsBasedOnDomainAction,
  getRecommendBannerAction,
} from "../../../redux/actions";
import { getCouponByIdAction } from "../../../redux/actions";
import { getImageUrl } from "../../../utils";
import CentralCouponRedirect from "../../../utils/couponRedirect";
import { BASE_URL } from "../../../services/axiosInstance";

const DealSectionOne = () => {
  const dispatch = useDispatch();

  const { couponbaseondomainList } = useSelector(
    (state) => state.CouponBasedOnDomain
  );
  const { getRecommendBanner } = useSelector((state) => state.Coupon);

  const [useRedirectComponent, setUseRedirectComponent] = useState(false);

  useEffect(() => {
    dispatch(getRecommendBannerAction({ dimension: "300 * 250" }));
  }, [dispatch]);

  useEffect(() => {
    function fetch() {
      dispatch(getCouponsBasedOnDomainAction());
    }
    fetch();
  }, [dispatch]);

  const [showMore, setShowMore] = useState(false);
  const handleShow = () => {
    setShowMore(!showMore);
  };

  return (
    <section  id="page" class="container">
      {useRedirectComponent && (
        <CentralCouponRedirect
          e={useRedirectComponent.e}
          coupon={useRedirectComponent.o}
          setUseRedirectComponent={setUseRedirectComponent}
        ></CentralCouponRedirect>
      )}
      <div class="thirteen" id="newly-head" style={{ margin: "0 10% 2vh 0" }}>
        <h1 style={{ background: "#eeee10" }}>
          <i style={{ marginRight: "2px" }} class="fa fa-star"></i> Top Deals
        </h1>
      </div>
      <div className="grid-container2">
        {couponbaseondomainList &&
          couponbaseondomainList.length > 0 &&
          couponbaseondomainList.slice(0, showMore ? 17 : 8).map((o, i) => {
            return (
              <>
                {i === 4 && (
                  <div class="card" id="banner-card-n">
                    <div class="card-header cursor-pointer">
                      <img
                        onClick={() =>
                          window.open(
                            getRecommendBanner[0]?.redirect_url,
                            "_blank"
                          )
                        }
                        src={`${BASE_URL}/${getRecommendBanner[0]?.image_upload}`}
                        alt=""
                      />
                    </div>
                    <div class="card-body"> </div>
                  </div>
                )}

                <div class="card">
                  <div class="card-header">
                    <img src={getImageUrl(o?.store?.image)} alt="" />
                  </div>
                  <div class="card-body">
                    {o?.category !== "" && (
                      <span
                        class="tag2 tag2-purple"
                        style={{ backgroundColor: "orangered" }}
                      >
                        <i class="ti-tag"></i>
                        {o?.store?.name
                          ? o?.store?.name
                          : o?.category?.length > 28
                          ? o?.category?.substring(0, 28) + "..."
                          : o?.category}
                      </span>
                    )}
                    <strong>{o?.name?.slice(0, 50)}</strong>
                  </div>
                  <button
                    id="show-code"
                    style={{ backgroundColor: "black", color: "#fff" }}
                    // onClick={(e) => {
                    //         e.preventDefault();
                    //         dispatch(getCouponByIdAction({ coupon_id: o?._id }));
                    //         window.open(
                    //           `/couponsredirect/coupon/${
                    //             o?.store?.slug?.replace(" ", "") ||
                    //             o?.store?.name?.replace(" ", "")
                    //           }/${o?._id}`,
                    //           "_blank"
                    //         );}}

                    onClick={(e) => {
                      e.preventDefault();
                      setUseRedirectComponent({ e, o });
                    }}
                  >
                    <i
                      style={{ marginRight: "2px" }}
                      class="fa fa-folder-open"
                    ></i>{" "}
                    Show code
                  </button>
                </div>
              </>
            );
          })}
      </div>
      <div
        style={{
          textAlign: "center",
          padding: "2vh 0 1vh 0",
          fontSize: ".7rem",
        }}
      >
        <span
          className="cursor-pointer"
          style={{
            border: "2px solid black",
            padding: "4px 10px",
            borderRadius: "5px",
            background: "#eeee10",
            color: "black",
            fontWeight: "700",
          }}
          onClick={handleShow}
        >
          Show more{" "}
        </span>
      </div>
    </section>
  );
};

export default DealSectionOne;
