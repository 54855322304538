import React from 'react'
import DealSectionOne from './DealSectionOne'
import MainSection from './MainSection'
import NewsLetter from './NewsLetter'
import NewlyAddedStores from './NewlyAddedStores'
import MostTrending from './MostTrending'
import Category from "./MainSectionSidebar";
import HBanner from "./Banners2";
import {getEndUserAction} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";


const Home = () => {

  const dispatch = useDispatch();


  const { getEndUser } = useSelector((state) => state.Coupon);


   React.useEffect(() => {
    dispatch(getEndUserAction());
  }, [dispatch]);

  
  
  return (
    <div >
      <MainSection />
      <div>
      <MostTrending  />
      </div>
      <DealSectionOne />
      <HBanner getEndUser={getEndUser} />
      <NewlyAddedStores />
      <div className='container'>
      <Category />
      <NewsLetter />
      </div>
    </div>
  )
}

export default Home
