import React from "react";
import MainSectionContent from "./MainSectionContent";
import MainSectionSidebar from "./MainSectionSidebar";

const MainSection = () => {
  return (
    <div class="container rPadding" id="default-home-b">
      <div class="row" style={{minHeight:440}} className='mainSection'>
        <MainSectionSidebar />
        <MainSectionContent />
      </div>
    </div>
  );
};
export default MainSection;
