import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  token: "",
  userData: null,
  role:''
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.LOGIN_ADMIN_SUCCESS:
      if(typeof action.payload === 'object'){
        sessionStorage.setItem("token", action.payload.token);
        sessionStorage.setItem("role", action.payload.role);
      } else {
        sessionStorage.setItem("token", action.payload);
      }
      return {
        ...state,
        loading: false,
        token: typeof action.payload === 'object' ? action.payload.token : action.payload,
        role: typeof action.payload === 'object' ? action.payload.role : action.payload
      };
    case actionTypes.LOGIN_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case actionTypes.LOGOUT_ADMIN_SUCCESS:
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("role");
      return { ...state, token: "", loading: false };

    case actionTypes.LOGOUT_ADMIN_FAILURE:
      return { ...state, loading: false };
    
    case actionTypes.LOGOUT_BLOCKED_USER_SUCCESS:
      return {
        ...state,
        token: "",
        loading: false,
        error: true,
        errorMessage: "user does not have access to the portal",
      };
    
    case actionTypes.LOGOUT_BLOCKED_USER_FAILURE:
      return { ...state, loading: false };

    case actionTypes.RESET_LOGIN_STATUS:
      return { ...state, loading: false, error: false, errorMessage: "" };
    default:
      return { ...state };
  }
};

export default login;
