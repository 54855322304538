import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from 'react-router-dom';
import { getImageUrl } from "../../../utils";
import { getNewlyAddedStores } from "../../../redux/stores/actions";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.png";
import { getTopVisitedStores } from "../../../redux/stores/actions";
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "nuka-carousel";

const NewlyAddedStores = () => {

const { newlyAddedStores, } = useSelector((state) => state.Stores);

const dispatch = useDispatch();

 const { topRatedStores } = useSelector((state) => state.Stores);

  React.useEffect(() => {
    dispatch(getTopVisitedStores());
  }, [dispatch]);

  React.useEffect(()=>{
    dispatch(getNewlyAddedStores());
  }, [dispatch])


  return(

    <section id="h4-mt-cont" class="container">
        
<div style={{marginBottom:"2%"}}>
  <h1 className="h4-section-header"
  style={{maxWidth:"15rem"}}>
  MOST TRENDING STORES &nbsp;
  <i className="fa fa-fire"></i></h1>
</div>
<Carousel>
<div className="carousel-stores-h4-grid">
  { topRatedStores &&
            topRatedStores.length > 0 &&
            topRatedStores.slice(0, 12).map((data, i)=>{
      return(
       <div class="card" style={{borderRadius:"0", border:"1px dotted #EF4444"}}>
    <div class="card-header" style={{height:"100px", padding:"5px"}}>
       <Link to={`/store/${data?.store_details?.slug}`}>
                        <img src={getImageUrl(data?.store_details?.image,PlaceHolderImg)}
                          style={{height: "100%",width: "100%",objectFit: "contain",}}
                          alt=''
                        />
                      </Link>
    </div>
    <div class="card-body">    
      <strong style={{color:"#7F1D1D", fontSize:"12px"}}>
        {data?.store_details?.slug?.slice(0, 25)?.toUpperCase()}
      </strong>
    </div>
     
       </div>
        )
    })}
</div>
<div className="carousel-stores-h4-grid">
  { newlyAddedStores &&
            newlyAddedStores.length > 0 &&
            newlyAddedStores.slice(11, 21).map((data, i)=>{
      return(
       <div class="card" style={{borderRadius:"1px"}}>
    <div class="card-header" style={{height:"100px", padding:"5px"}}>
       <Link to={`/store/${data?.slug}`}>
                        <img src={getImageUrl(data?.image,PlaceHolderImg)}
                          style={{height: "100%",width: "100%",objectFit: "contain",}}
                          alt=''
                        />
                      </Link>
    </div>
    <div class="card-body">    
      <strong style={{color:"#7F1D1D", fontSize:"12px"}}>
        {data?.slug.toUpperCase()}
      </strong>
    </div>
     
       </div>
        )
    })}
</div>
      </Carousel>
              </section>
              )
            }
export default memo(NewlyAddedStores);