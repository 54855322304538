import React, { memo, useEffect, useState } from "react";

import darkLogo from "../../../assets/images/logo-dark.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryAction, getFooterAction } from "../../../redux/actions";
import { Link, useLocation } from "react-router-dom";
import { getImageUrl } from "../../../utils";
import CustomLoader from "../../UI/CustomLoader";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import UpdateDateMacrosInString from "../../UI/UpdateDateMacrosInString";
import Macro from "../../../utils/ReplaceMacro";

const Footer = ({ history }) => {
  const {
    Category: { categoryList },
    Stores: { storesList },
  } = useSelector((state) => state);

  let activeStoresList = storesList?.filter(
    (ele) => ele.is_archived === false && ele.is_paused === false
  );

  const dispatch = useDispatch();
  const { domainData, loading: loadingWebsiteData } = useSelector(
    (state) => state.Website
  );

  // Analytics
  const location = useLocation();

  useEffect(() => {
    function set() {
      ReactGA.initialize("UA-200647590-1");
      ReactGA.pageview(location.pathname + location.search);
    }
    set();
  }, [location]);

  useEffect(() => {
    function fetch() {
      dispatch(getAllCategoryAction());
      dispatch(getFooterAction());
    }
    fetch();
  }, [dispatch]);

  const getdata = (websiteData) => {
    const data = [];

    if (typeof websiteData === "string") {
      const a = websiteData?.split("</script>");
      a.forEach((ele) => {
        if (ele.includes("async")) {
          const da = ele.match(/(((https?:\/\/)|(www\.))[^\s]+)/g);
          if (da) {
            data.push({
              isAsync: true,
              src: da[0]?.slice(0, -2),
              body: null,
            });
          }
        } else {
          data.push({
            body: ele?.slice(8),
          });
        }
      });
    }
    return data;
  };

       const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <footer id="footer" style={{background:"rgb(220, 38, 38)", 
    color:"#FEF2F2", padding:"30px 0 20px 0", borderRadius:"25px 25px 0 0"}}>
      <div className="container">
        <Helmet>
          {
            // eslint-disable-next-line array-callback-return
            getdata(domainData?.header_js).map((item) => {
              if (item.isAsync) {
                return <script async src={item.src}></script>;
              }
              if (item.body) {
                return <script>{item.body}</script>;
              }
            })
          }
                  </Helmet>
        <div className="col-sm-3">
          <div>
            {loadingWebsiteData ? (
              <CustomLoader
                customStyle={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "enter",
                }}
              />
            ) : (
              <img
                src={getImageUrl(domainData?.logo, darkLogo)}
                alt=""
                // className="img-responsive"
                // alt="#"
                style={{
                  height: "50%",
                  width: "100px",
                  borderRadius: "5px",
                  margin:"20px 0 10px 0"
                }}
                className="img-responsive logo"
              />
            )}
          </div>
          <p style={{color:"#FEF2F2"}}>{domainData?.footer_about_us}</p>
          <Link to="/privacypolicy">
            <p  style={{color:"#FEF2F2"}}>
              <strong style={{color:"#FEF2F2"}}>Privacy Policy</strong>
            </p>
          </Link>
          <Macro input={domainData?.footer_text?.replace("red", "white")} />
        </div>
        <div className="col-sm-5">
          <h5 className="h4-footer" style={{color:"#FEF2F2"}}>
                  <i className="fa fa-fire"></i> POPULAR STORES</h5>
          <ul className="tags cursor-pointer" >
            {activeStoresList &&
              activeStoresList.length > 0 &&
              activeStoresList?.slice(0, 10).map((store) => (
                <li key={store.slug} 
                onClick={()=>{
                  window.open(`/store/${store?.slug}`);
                }}>
                  
                  <span
                    className="tag-h4"
                     style={{background:"#991B1B", color:"#FEF2F2", border:"1px dotted white"}}
                  >
                  <i className="fa fa-gift"></i>
                    &nbsp;<UpdateDateMacrosInString input={store?.name} />
                  </span>
                </li>
              ))}
          </ul>
        </div>
        <div className="col-sm-2">
          <h5 className="h4-footer" style={{color:"#FEF2F2"}}>
                  <i className="fa fa-bars"></i> CATEGORIES</h5>
          <ul className="list-unstyled">
            {categoryList?.slice(0, 4).map((el) => (
              <li
              onClick={()=>{
                window.open(`/category/${el.category}`);
              }}
                className="category"

              >
                {el.category}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-sm-2">
          <h5 className="h4-footer" style={{color:"#FEF2F2"}}>
                  <i className="fa fa-envelope"></i> CONTACT US</h5>
          
          <div>
            <div className="row d-flex">
              <div className="col-md-2 col-sm-1">
                <i className="fa fa-envelope"></i>
              </div>
              <div className="col-md-10 col-sm-11">
                {domainData?.contact?.email}
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-2 col-sm-1">
                <i className="fa fa-phone"></i>
              </div>
              <div className="col-md-10 col-sm-11">
                {domainData?.contact?.phone}
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-2 col-sm-1">
                <i className="fa fa-map-marker"></i>
              </div>
              <div className="col-md-10 col-sm-11">
                {domainData?.contact?.address}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* {renderHTML(websiteData)} */}
      <button
        className="btn btn-success"
        title="Scroll to top"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          backgroundColor: "rgb(239, 68, 68)",
          borderColor: "white",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1.5rem",
          borderRadius:"25px 25px 0 0",
          padding: "10px 13px 1px 13px"
        }}
        onClick={() => {
        window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
      }}
      >
        <i className="ti-arrow-circle-up"></i>
      </button>
    </footer>
  );
};

export default memo(Footer);
