import * as actionTypes from "./actionTypes";

export const getWebsiteDataAction = () => ({
  type: actionTypes.GET_WEBSITE_DATA,
});

export const getWebsiteDataByDomainAction = (setDomainDataSession, domainDataSession) => ({
  type: actionTypes.GET_WEBSITE_DATA_BY_DOMAIN, setDomainDataSession, domainDataSession
});

export const getFooterAction = () => ({
  type: actionTypes.GET_FOOTER,
});
export const getGoogleVerificationCodeByDomainAction = () => ({
  type: actionTypes.GET_GOOGLE_VERIFICATION_CODE_DATA,
});
export const getAdBannerByDomainAction = () =>{
 return {type: actionTypes.GET_AD_BANNER_BY_DOMAIN};
} 

export const getRecommendBannerActionTwo = (payload) => ({
  type: actionTypes.RECOMMEND_BANNER_BY_DOMAIN_TWO,
  payload,
});


export const getFeatureCardsAction = (payload) => ({
  type: actionTypes.GET_FEATURE_CARDS,
  payload,
});

