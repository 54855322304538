import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { postNewsletteraction, resetNewsletterStateAction} from "../../redux/Newsletter/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from "../UI/CustomLoader";

export default function NewsLetter() {

    const { domainData} = useSelector(
    (state) => state.Website
  );
const { successMessage, loading, errorMessage } = useSelector(
    (state) => state.Newsletter
  );
  const enterSearch = React.useRef();
   const dispatch = useDispatch();

  const [email_id, setEmail] = useState("");


const validate = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

  const handleNewsletter = () => {
  if(!(validate(email_id))){
    toast.error("Please provide a valid email address.")
    return;
  }
    dispatch(postNewsletteraction({ email_id }));

  };

React.useEffect(()=>{
   if(successMessage){
      toast.success(successMessage);
    dispatch(resetNewsletterStateAction());
    }

    if(errorMessage){
      toast.error(errorMessage);
    dispatch(resetNewsletterStateAction());
    }

  },[dispatch, errorMessage, loading, successMessage]) 



	return(
    <>

    { loading && <CustomLoader /> }
		<div  id="newsletter3"
         style={{padding: "2% 0", marginBottom: "2%", backgroundColor: domainData ? domainData.header_color : "#black", textAlign:"center"}}>
		<p style={{color: "white", display:"inline", paddingLeft: "5px"}}>
    Sign up for the weekly email newsletter with the best money-saving coupons.
    </p>
    <div className="input-group" style={{whiteSpace: "nowrap", margin:"1% auto", paddingRight:"8%"}}>
    <input
    required
    type="email"
    className="form-control shadow-none" 
    placeholder="your email.." 
    style={{ borderRadius: "3% 0 0 3%"}}
    value={email_id}
    onChange={((e)=> setEmail(e.target.value))}
    />
    <div className="input-group-append" style={{ display: "inline-block"}}>
      <button
      ref={enterSearch} 
      onClick={() => handleNewsletter()}
      style={{
        backgroundColor: "inherit",
        borderColor: "white",
        borderLeft: "none",
        border: "3px solid white",
        borderRadius: "0 4px 4px 0",
        fontWeight: "bold",
        display: "inline",
        fontSize: "1.17rem"}} class="btn btn-danger shadow-none" type="button">

      Subscribe
      </button>
    </div>
    </div>
		
      <p style={{color: "white"}}>We will never share your email with a third-party.</p>

      <ToastContainer
        position='top-right'
      />

      </div>
      </>
		);
}