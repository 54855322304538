import React, { useEffect, useMemo } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "nuka-carousel";
import {
  getAdBannerByDomainAction
} from "../../../redux/actions";
import { getImageUrl } from "../../../utils";

const MainSectionContent = () => {
  const dispatch = useDispatch();
  const { adBanners } = useSelector((state) => state.Website);

  useEffect(() => {
    dispatch(getAdBannerByDomainAction());
  }, []);

  const items = useMemo(() => {
    let arr = [];
    adBanners?.forEach((i) => {
      if (i.banner_dimension === "1480 * 630") {
        let obj = {
          src: getImageUrl(i.image_upload),
          url: i.image_url,
          altText: "ad"
        };
        arr.push(obj);
      }
    });
    return arr;
  }, [adBanners]);

  return (
    <div>
      <Carousel>
        {items.map((o) => (
          <img
            key={o.url}
            className="cursor-pointer"
            id="carousel-img-4"
            src={o.url || o.src}
            alt={o.altText}
            onClick={() => window.open(o?.redirect_url, "_blank")}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default MainSectionContent;
