import React, { memo } from 'react'
import DealSectionOne from './DealSectionOne'
import MainSection from './MainSection'
import NewlyAddedStores from './NewlyAddedStores'
import {  useSelector } from "react-redux";

const Home = () => {

  const { getEndUser } = useSelector((state) => state.Coupon);

  return (
    <div className="container" style={{background:"#EDE9FE50", paddingTop:"1px"}}>
      <MainSection />
      <DealSectionOne getEndUser={getEndUser} />
      <NewlyAddedStores />
    </div>
  )
}

export default memo(Home)
