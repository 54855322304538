export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const LOGIN_ADMIN_SUCCESS = 'LOGIN_ADMIN_SUCCESS';
export const LOGIN_ADMIN_FAILURE = 'LOGIN_ADMIN_FAILURE';

export const LOGOUT_ADMIN = 'LOGOUT_ADMIN';
export const LOGOUT_ADMIN_SUCCESS = 'LOGOUT_ADMIN_SUCCESS';
export const LOGOUT_ADMIN_FAILURE = 'LOGOUT_ADMIN_FAILURE';

export const LOGOUT_BLOCKED_USER='LOGOUT_BLOCKED_USER';
export const LOGOUT_BLOCKED_USER_SUCCESS='LOGOUT_BLOCKED_USER_SUCCESS';
export const LOGOUT_BLOCKED_USER_FAILURE='LOGOUT_BLOCKED_USER_FAILURE';

export const RESET_LOGIN_STATUS='RESET_LOGIN_STATUS';

export const SET_LOGIN_LOADING='SET_LOGIN_LOADING';


