import React from "react";
import DealSectionOne from "./DealSectionOne";
import MainSection from "./MainSection";
import NewsLetter from "./NewsLetter";
import NewlyAddedStores from "./NewlyAddedStores";
import MostTrending from "./MostTrending";

const Home = ({ history, match }) => {
  return (
    <div>
      <MainSection />
      <div>
        <MostTrending />
      </div>
      <DealSectionOne history={history} match={match} />
      <NewlyAddedStores />
      <NewsLetter />
    </div>
  );
};

export default Home;
