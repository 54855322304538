import React, { memo, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/home5.css";
import { getCouponListByNameAction } from "../../../redux/actions";
import { getStoresByNameAction } from "../../../redux/stores/actions";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../../utils";

const Header = () => {
  const dispatch = useDispatch();

  const { domainData } = useSelector((state) => state.Website);

  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = () => {
    dispatch(getCouponListByNameAction({ name: searchValue }));
    dispatch(getStoresByNameAction({ name: searchValue }));
    enterSearch.current.click();
  };

  const enterSearch = React.useRef();

  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{
        padding: "0",
        position: "initial",
        backgroundColor: "#8B5CF6",
        width: "100vw",
        marginBottom: "5px",
      }}
    >
      <div className="container" style={{ paddingTop: "4px" }}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={() => setIsOpen(!isOpen)}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {domainData?.logo ? (
            <Link to="/">
              <img
                src={getImageUrl(domainData?.logo, logo)}
                alt=""
                className="img-responsive logo-nav-h3"
                id="logo-nav-h4"
                style={{
                  objectFit: "contain",
                  height: "40px",
                }}
                loading="lazy"
              />
            </Link>
          ) : (
            " "
          )}{" "}
        </div>

        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="bs-example-navbar-collapse-1"
        >
          <input
            type="text"
            placeholder="What are you looking for?"
            id="h5-nav-input"
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ borderRadius: "2px -1px -1px 2px", height: "35px" }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            value={searchValue}
          />
          <Link to="/searchpage">
            <button
              ref={enterSearch}
              onClick={handleSearch}
              id="h5-nav-search-but"
              style={{ background: "#7C3AED", color:"#fff"}}
            >
              Search
          </button>
          </Link>
          <ul
            className="nav navbar-nav navbar-right"
            style={{ font: "Times New Roman" }}
          >
            <li>
              <Link to="/" className="page-scroll">
                 Home
              </Link>
            </li>
            <li>
              <Link to="/aboutus" className="page-scroll">
                 About us
              </Link>
            </li>
            <li>
              <Link to="/categories" className="page-scroll">
                 Categories
              </Link>
            </li>
            <li>
              <Link to="/store" className="page-scroll">
                 Stores
              </Link>
            </li>
            <li>
              <Link to="/contactus" className="page-scroll">
                 Contact us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default memo(Header);
