import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../utils";
import dayjs from "dayjs";
import PlaceholderImages from "../../assets/images/PlaceholderImage.png";
import { getCouponByIdAction } from "../../redux/actions";
import { Link } from "react-router-dom";
import UpdateDateMacrosInString from "../UI/UpdateDateMacrosInString";
import CentralCouponRedirect from "../../utils/couponRedirect";

function Coupon({ couponData, setSelectedCoupon }) {
  const dispatch = useDispatch();

  const { domainData } = useSelector((state) => state.Website);
  const { storesList } = useSelector((state) => state.Stores);
  const [imageSource, setImageSource] = useState(
    couponData.image
      ? couponData.image
      : getImageUrl(couponData?.store_id?.image, PlaceholderImages)
  );
  const [useRedirectComponent, setUseRedirectComponent] = useState(false);

  const countDownTimer = (expDate) => {
    // Get today's date and time
    let now = new Date().getTime();
    let distance = new Date(expDate).getTime() - now;

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    if (distance < 0) {
      return <p>Expired</p>;
    } else {
      return (
        <p>
          {" "}
          <i class="ti-timer"></i> {days} <b>d</b> {hours} <b>hr</b> {minutes}{" "}
          <b>min</b>
        </p>
      );
    }
  };

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <div class="deal-entry">
      {useRedirectComponent && (
        <CentralCouponRedirect
          e={useRedirectComponent.e}
          coupon={useRedirectComponent.couponData}
          setUseRedirectComponent={setUseRedirectComponent}
        ></CentralCouponRedirect>
      )}
      <div class="image">
        <Link to={`/store/${couponData?.store_id?.slug}`}>
          <img
            src={imageSource}
            alt="#"
            style={{ height: "180px", width: "100%", objectFit: "contain" }}
            onError={() => {
              setImageSource(
                getImageUrl(couponData?.store_id?.image, PlaceholderImages)
              );
            }}
          />
        </Link>
        {couponData?.category !== "" && (
          <span
            class="bought"
            style={{ backgroundColor: domainData?.header_color }}
          >
            <i class="ti-tag"></i>
            {couponData?.store_id?.name
              ? couponData?.store_id?.name
              : couponData?.category?.length > 28
              ? couponData?.category?.substring(0, 28) + "..."
              : couponData?.category}
          </span>
        )}
      </div>
      <div
        class="title"
        style={{
          marginRight: "10px",
          borderLeft: `5px solid ${domainData?.header_color}`,
        }}
      >
        <a
          href="/"
          target="_blank"
          rel="noreferrer"
          title="ATLETIKA 3 mēnešu abonements"
          onClick={(e) => {
            e.preventDefault();
            setSelectedCoupon(couponData);
            dispatch(getCouponByIdAction({ coupon_id: couponData?._id }));
            window.open(
              `/couponsredirect/coupon/${
                couponData?.store_id?.slug?.replace(" ", "") ||
                couponData?.store_id?.name?.replace(" ", "")
              }/${couponData?._id}`,
              "_blank"
            );
          }}
          style={{
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "wrap",
            height: "50px",
          }}
        >
          <UpdateDateMacrosInString
            input={
              couponData?.title?.length > 48
                ? couponData?.title.substring(0, 48) + "..."
                : couponData?.title
            }
          />
        </a>
      </div>
      <div className="entry-content">
        <p style={{ height: "25px" }}>``
          <UpdateDateMacrosInString
            input={
              couponData?.description?.length > 60
                ? couponData?.description?.substring(0, 60) + "..."
                : couponData?.description
            }
          />
        </p>
      </div>
      <footer class="info_bar clearfix" style={{ minHeight: "100px" }}>
        <ul class="unstyled list-inline row">
          <li class="time col-sm-7 col-xs-6 col-lg-8">
            <p style={{ margin: 0, alignItems: "left" }}>
              {couponData.expiry_date && (
                <>
                  Expiry: {dayjs(couponData.expiry_date).format("DD MMMM YYYY")}
                </>
              )}
            </p>
            <p style={{ margin: 0, alignItems: "left" }}>
              {couponData.expiry_date && countDownTimer(couponData.expiry_date)}
            </p>
          </li>
          <li class="info_link col-sm-5 col-xs-6 col-lg-4">
            <a
              href="/"
              class="btn btn-block btn-default btn-raised btn-sm"
              // onClick={(e) => {
              //   e.preventDefault();

              //   setSelectedCoupon(couponData);
              //   dispatch(getCouponByIdAction({ coupon_id: couponData?._id }));
              //   window.open(
              //     `/couponsredirect/coupon/${
              //       couponData?.store_id?.slug?.replace(" ", "") ||
              //       couponData?.store_id?.name?.replace(" ", "")
              //     }/${couponData?._id}`,
              //     "_blank"
              //   );

              //   // if(history.location.pathname.split('/').length > 2) {
              //   //   let routePath = `/category/${history.location.pathname.split('/')[2]}/coupon/${couponData?.store_id?.name.replace(' ','')}/${couponData?._id}`
              //   //   history.push({pathname:`${routePath}`});
              //   //   setShareOfferLink(routePath)
              //   //   //  window.open(`/couponsredirect/category/${history.location.pathname.split('/')[2]}/coupon/${couponData?.store_id?.name.replace(' ','')}/${couponData?._id}`, '_blank');
              //   // } else {
              //   //   let routePath =`/coupon/${couponData?.store_id?.slug?.replace(' ','')}/${couponData?._id}`
              //   //   // window.open(`/couponsredirect/coupon/${couponData?.store_id?.slug?.replace(' ','')}/${couponData?._id}`, '_blank');
              //   //   history.push({pathname:`${routePath}`});
              //   //   setShareOfferLink(routePath)
              //   // }
              // }}
              onClick={(e) => {
                e.preventDefault();
                setUseRedirectComponent({ e, couponData });
              }}
            >
              View
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default memo(Coupon);
