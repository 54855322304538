export const scriptEncoding = (websiteData) => {
  const data = [];

  if (typeof websiteData === "string") {
    const a = websiteData?.split("</script>");

    a.forEach((ele) => {
      if (ele.includes("async")) {
        const da = ele.match(/(((https?:\/\/)|(www\.))[^\s]+)/g);
        if (da) {
          data.push({
            isAsync: true,
            src: da[0]?.replace(/["'>]+$/, ""),
            // src:da[0]?.slice(0,-2),
            body: null,
          });
        } else {
          const srcValue = ele.match(/src="([^"]*)"/);
          if (srcValue && srcValue[1].startsWith("//")) {
            // If src attribute value starts with '//', it's already a complete URL
            const updatedSrc = "https:" + srcValue[1];
            data.push({
              isAsync: true,
              src: updatedSrc,
              body: null,
            });
          }
        }
      } else {
        data.push({
          body: ele?.slice(8),
        });
      }
    });
  }
  // eslint-disable-next-line array-callback-return
  return data.map((item) => {
    if (item.isAsync) {
      return (
        <script async src={item.src}>
          {" "}
        </script>
      );
    }
    if (item.body) {
      return <script>{item.body}</script>;
    }
  });
};
