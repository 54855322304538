import * as actionTypes from "./actionTypes";

const initialState = {
  couponbaseondomainList:[],
  error: null,
  errorMessage:'',
  success:false,
  successMessage:'',
  loading: false,
};

const CouponBasedOnDomain = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COUPON_BASED_ON_DOMAIN_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_COUPON_BASED_ON_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        couponbaseondomainList: action.payload
      };
    case actionTypes.GET_COUPON_BASED_ON_DOMAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        couponbaseondomainList:[]
      };
   
    case actionTypes.RESET_COUPON_BASED_ON_DOMAIN_STATUS:
      return { ...state, loading: false, error: false, errorMessage: "", successMessage:'', success:false };
    default:
      return { ...state };
  }
};

export default CouponBasedOnDomain;
