import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from 'react-router-dom';
import { getImageUrl } from "../../../utils";
import { getNewlyAddedStores } from "../../../redux/stores/actions";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.png"
import {useState} from 'react';
import { postNewsletteraction, resetNewsletterStateAction} from "../../../redux/Newsletter/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewlyAddedStores() {

const { newlyAddedStores, loading } = useSelector((state) => state.Stores);

const dispatch = useDispatch();

  React.useEffect(()=>{
    dispatch(getNewlyAddedStores());
  }, [dispatch])

   
const { successMessage, errorMessage } = useSelector(
    (state) => state.Newsletter
  );
  const enterSearch = React.useRef();

  const [email_id, setEmail] = useState("");


const validate = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

  const handleNewsletter = () => {
  if(!(validate(email_id))){
    toast.error("Please provide a valid email address.")
    return;
  }
    dispatch(postNewsletteraction({ email_id }));

  };

React.useEffect(()=>{
   if(successMessage){
      toast.success(successMessage);
    dispatch(resetNewsletterStateAction());
    }

    if(errorMessage){
      toast.error(errorMessage);
    dispatch(resetNewsletterStateAction());
    }

  },[dispatch, errorMessage, loading, successMessage]) 


  return(

    <section id="page" class="container" style={{padding:"2% 0 2% 0", margin:"2% 0"}}>
   <div style={{position:"relative"}}>
        
<div class="twelve" id="newly-head " style={{marginBottom:"2%"}}>
  <h1>Newly added stores</h1>
</div>
<div id="newly-news">
          <div  id="grid-container-3">
                {newlyAddedStores && newlyAddedStores.slice(0, 5).map((data, key) => (
                  <div key={key}>
                     <div className="featured-stores"  style={{backgroundColor:"white", boxShadow: " 3px 3px 6px 0px rgb(251, 90, 188, .4) inset, -3px -3px 6px 1px rgba(255,255,255,0.5) inset", borderRadius: "10px"}}>
                      <Link to={`/store/${data.slug}`}>
                        <img src={getImageUrl(data?.image,PlaceHolderImg)}
                          style={{height: "100%",width: "100%",objectFit: "contain",}}
                          alt=''
                        />
                      </Link>
                    </div>
                  </div>
                ))}
                <div key={7}>
                    <div className="featured-stores" style={{backgroundColor:"white", boxShadow: " 3px 3px 6px 0px rgb(251, 90, 188, .4) inset, -3px -3px 6px 1px rgba(255,255,255,0.5) inset", borderRadius: "10px", textAlign:"center", paddingTop:"20%"}}>
                      <Link to={`/newlyaddedstores`} style={{ color: "purple", fontWeight:"bold", verticalAlign:"center"}}>
                        
                        Go to new stores
                      </Link>
                    </div>
                  </div>
              </div>
              </div>

              <div id="newsletter3" class="subscribe-wrapper" style={{position:"absolute", right:"1%", top:"31%", }}>
    <h4 style={{color:"#f82caa"}}>SUBSCRIBE TO OUR NEWSLETTER</h4>
    <input 
    style={{color:"#e90a96"}}
    type="email" name="email" class="subscribe-input" 
    placeholder="Your e-mail" value={email_id} 
    onChange={((e)=> setEmail(e.target.value))}
    />
    <div class="submit-btn" 
      ref={enterSearch} 
      onClick={() => handleNewsletter()}
      >SUBMIT
      </div>
  </div>

              </div>
              
      <ToastContainer
        position='top-right'
      />

              </section>
              )
            }