import React from "react";
import DefaultNav from "../Header/Header";
import NavTwo from "./home2/Navbar";
import NavThree from "./home3/Navbar";
import NavFour from "./home4/Navbar";
import NavFive from "./home5/Navbar";
import {useSelector} from "react-redux";
import { useLocation } from "react-router-dom";

export default function HomeChanger() {
  const { domainData } = useSelector(
    (state) => state.Website
  );

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if(window.location.pathname.includes("mr")){
    return <div></div>;
  }else{
    switch (domainData?.home_page) {
    case "Home Page One":
      return <NavTwo />;
    case "Home Page Two":
      return <NavThree />;
    case "Home Page Three":
      return <NavFive />;
    default:
      return <DefaultNav />;
  }
  }
}
