import { fork, put, all, takeLatest } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import API from "../../services/axiosInstance";
import { stringifyErrorMessage } from "../../utils";

function* getAllCategorySaga() {
  try {
    yield put({ type: actionTypes.SET_CATEGORY_LOADING });
    const { data } = yield API.get("/api/end-user/coupon/getUniqueCategoryAndCount", {
      params: {
        district: true,
      },
    });

    yield put({
      type: actionTypes.GET_ALL_CATEGORY_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALL_CATEGORY_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
function* getCouponsByCategorySaga({ payload,meta}) {
  try {
    yield put({ type: actionTypes.SET_CATEGORY_LOADING });
    const { data } = yield API.get("/api/end-user/coupon/getCouponsBasedOnCategory", {
      params: {
        category: payload,...meta
      
      },
    });

    yield put({
      type: actionTypes.GET_COUPONS_BY_CATEGORY_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPONS_BY_CATEGORY_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

export function* watchGetAllCategory() {
  yield takeLatest(actionTypes.GET_ALL_CATEGORY, getAllCategorySaga);
}

export function* watchGetCouponsByCategorySaga() {
  yield takeLatest(
    actionTypes.GET_COUPONS_BY_CATEGORY,
    getCouponsByCategorySaga
  );
}

function* CategorySaga() {
  yield all([fork(watchGetAllCategory), fork(watchGetCouponsByCategorySaga)]);
}

export default CategorySaga;
