import { lazy } from "react";

// import Register from "../components/Auth/Register";
import Home from "../components/Home/index";
const Home2 = lazy(() => import("../components/Home/home2/Home2"));
const Home3 = lazy(() => import("../components/Home/home3/Home2"));
const Home4 = lazy(() => import("../components/Home/home4/Home2"));
const Category = lazy(() => import("../components/Category/Category"));
const Store = lazy(() => import("../components/Store/Store"));
const Stores = lazy(() => import("../components/Store/Stores"));
const MetaRedirect = lazy(() => import("../components/Store/MetaRedirect"));
const AboutUs = lazy(() => import("../components/AboutUs/AboutUs"));
const PrivacyPolicy = lazy(() =>
  import("../components/PrivacyPolicy/PrivacyPolicy")
);
const Contact = lazy(() => import("../components/Contact/Contact"));
const CouponLists = lazy(() => import("../components/Coupons/CouponsList"));
const CouponRedireact = lazy(() => import("../components/UI/CouponRedireact"));
const ZeroRedirect = lazy(() => import("../components/UI/ZeroRedirect.js"));
const SearchPage = lazy(() => import("../components/SearchPage/SearchPage"));
const Categories = lazy(() => import("../components/Categories/Categories"));
const NewlyAddedStores = lazy(() =>
  import("../components/NewlyAddedStores/NewlyAddedStores")
);

const authProtectedRoutes = [
  { path: "/", exact: true, component: Home, pageId: 0 },
  { path: "/category/:category", exact: true, component: Category, pageId: 1 },
  { path: "/store/:storeSlug", exact: true, component: Store, pageId: 2 },
  { path: "/store", exact: true, component: Stores, pageId: 3 },
  { path: "/aboutus", exact: true, component: AboutUs, pageId: 4 },
  { path: "/privacypolicy", exact: true, component: PrivacyPolicy, pageId: 5 },
  { path: "/contactus", exact: true, component: Contact, pageId: 6 },
  { path: "/coupons", exact: true, component: CouponLists, pageId: 10 },
  { path: "/searchpage", exact: true, component: SearchPage, pageId: 16 },
  {
    path: "/couponsredirect/:storeName/:couponId",
    exact: true,
    component: CouponRedireact,
    pageId: 12,
  },
  {
    path: "/couponzr/store/:storeSlug/coupon/:storeName/:couponId",
    exact: true,
    component: ZeroRedirect,
    pageId: 23,
  },
  {
    path: "/couponsredirect/category/:category/coupon/:storeName/:couponId",
    exact: true,
    component: CouponRedireact,
    pageId: 13,
  },
  {
    path: "/couponsredirect/coupon/:storeName/:couponId",
    exact: true,
    component: CouponRedireact,
    pageId: 14,
  },
  {
    path: "/couponsredirect/store/:storeSlug/coupon/:storeName/:couponId",
    exact: true,
    component: CouponRedireact,
    pageId: 15,
  },
  {
    path: "/couponsredirect/store/:storeSlug/coupon/:storeName/:couponId/:mtrd",
    exact: true,
    component: CouponRedireact,
    pageId: 25,
  },
  {
    path: "/couponsredirect/store/:storeSlug/coupon/:storeName/:couponId/:s2s",
    exact: true,
    component: CouponRedireact,
    pageId: 27,
  },
  { path: "/categories", exact: true, component: Categories, pageId: 16 },
  {
    path: "/newlyaddedstores",
    exact: true,
    component: NewlyAddedStores,
    pageId: 17,
  },
  { path: "/mr", exact: true, component: MetaRedirect, pageId: 18 },
  { path: "/mr/:link", exact: true, component: MetaRedirect, pageId: 19 },
  {
    path: "/mr/store/:storeName/coupon/:couponId/:redirectionType",
    exact: true,
    component: MetaRedirect,
    key: 20,
  },
  { path: "/home2", exact: true, component: Home2, pageId: 21 },
  { path: "/home3", exact: true, component: Home3, pageId: 22 },
  { path: "/home4", exact: true, component: Home4, pageId: 22 },
  {
    path: "/couponzr/:storeName/:couponId",
    exact: true,
    component: ZeroRedirect,
    pageId: 24,
  }
 
];

const publicRoutes = [
  // { path:'/login' ,component: Login,pageId:0 },
  // { path:'/register' ,component: Register,pageId:1 },
];

export { authProtectedRoutes, publicRoutes };
