import React, { memo } from "react";
import '../../assets/css/loader.css'



const CustomLoader = ({ customStyle }) => {
  return (
    <div
      style={
        customStyle
          ? {
              height: 100,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              ...customStyle,
            }
          : {
              height: 100,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "blue",

            }
          
      }
    >
      <div class="lds-ring"  style={{ borderColor: 'blue transparent transparent transparent'}}><div></div><div></div><div></div><div></div></div>
    </div>
  );
};

export default memo(CustomLoader);
