import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Coupon from "../UI/Coupon";
import {
  getCouponsBasedOnDomainAction,
  getFeatureCardsAction,
  getRecommendBannerAction,
} from "../../redux/actions";
import CustomLoader from "../UI/CustomLoader";
import { getCouponByIdAction } from "../../redux/actions";
import { Link } from "react-router-dom";
import { zoomIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { getImageUrl } from "../../utils";
import { BASE_URL } from "../../services/axiosInstance";

const zoomInAnimation = keyframes`${zoomIn}`;

const ZoomInDiv = styled.div`
  animation: 1s ${zoomInAnimation};
`;

const DealSectionOne = ({ match }) => {
  const dispatch = useDispatch();

  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const { featureCards } = useSelector((state) => state.Website);
  const { couponbaseondomainList, loading } = useSelector(
    (state) => state.CouponBasedOnDomain
  );
  const { couponByID } = useSelector((state) => state.Coupon);
  const { getRecommendBanner } = useSelector((state) => state.Coupon);

  useEffect(() => {
    dispatch(getRecommendBannerAction({ dimension: "300 * 250" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFeatureCardsAction());
  }, []);

  useEffect(() => {
    dispatch(getCouponsBasedOnDomainAction());
  }, [dispatch]);

  useEffect(() => {
    function fetch() {
      if (
        match?.params?.couponId !== undefined &&
        couponByID?._id === undefined
      ) {
        dispatch(getCouponByIdAction({ coupon_id: match.params.couponId }));
      }
      if (
        match?.params?.couponId !== undefined &&
        couponByID?._id !== undefined
      ) {
        window.open(
          `/couponsredirect/coupon/${match.params.storeName}/${match.params.couponId}`,
          "_blank"
        );
      }
    }
    fetch();
  }, [match, couponByID, dispatch]);

  return (
    <section id="page" class="container">
      <div class="shadow bg-white mTop-30 frameLR">
        <div class="row custom-row">
          {featureCards.map((card) => (
            <div class="col-md-3 col-sm-4">
              <div class="l-element">
                <div class="box-icon">
                  <div class="icon-wrap" style={{ paddingLeft: "30%" }}>
                    <img
                      alt="icon"
                      src={getImageUrl(card?.icon)}
                      style={{ objectFit: "contain" }}
                      width="100"
                      height="100"
                    />
                  </div>
                  <div class="text">
                    <h4>{card?.title}</h4>
                    <p>{card?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div class="no-class mTop-30">
        <div class="row">
          <div class="col-sm-12 clearfix">
            <div class="hr-link">
              <hr class="mBtm-50" data-symbol="LATEST DEALS" />
              <div class="view-all">
                <Link to="/coupons">VIEW ALL</Link>
              </div>
            </div>
          </div>
        </div>
        <div class="row custom-row cursor-pointer">
          {loading ? (
            <CustomLoader />
          ) : (
            couponbaseondomainList &&
            couponbaseondomainList.length > 0 &&
            couponbaseondomainList.slice(0, 20).map((data, index) =>
              index === 3 && getRecommendBanner[0] ? (
                <div
                  className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                  // className="mb-1 pb-3"
                  key={index}
                  id="home1-banner"
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxHeight: "400px",
                    maxWidth: "315px",
                    marginLeft: "12px",
                  }}
                  onClick={() =>
                    window.open(getRecommendBanner[0]?.redirect_url, "_blank")
                  }
                >
                  <ZoomInDiv className="d-flex align-items-center cursor-pointer flex-column justify-content-center couponCard h-100">
                    <a href={getRecommendBanner[0]?.redirect_url}>
                      <img
                        style={{
                          objectFit: "contain",
                          maxWidth: "280px",
                        }}
                        src={`${BASE_URL}/${getRecommendBanner[0].image_upload}`}
                        alt="Banner 1"
                      />
                    </a>
                  </ZoomInDiv>
                </div>
              ) : index === 11 && getRecommendBanner[1] ? (
                <div
                  className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                  // className="mb-1 pb-3"
                  key={index}
                  id="home1-banner"
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "12px",
                    maxHeight: "400px",
                    maxWidth: "315px",
                  }}
                  onClick={() =>
                    window.open(getRecommendBanner[1]?.redirect_url, "_blank")
                  }
                >
                  <ZoomInDiv className="d-flex align-items-center cursor-pointer flex-column justify-content-center couponCard h-100">
                    <a href={getRecommendBanner[1]?.redirect_url}>
                      <img
                        style={{
                          objectFit: "contain",
                          maxWidth: "280px",
                        }}
                        src={`${BASE_URL}/${getRecommendBanner[1].image_upload}`}
                        alt="Banner 2"
                      />
                    </a>
                  </ZoomInDiv>
                </div>
              ) : index === 16 && getRecommendBanner[2] ? (
                <div
                  className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                  // className="mb-1 pb-3"
                  key={index}
                  id="home1-banner"
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "14px",
                    marginRight:"auto",
                    maxHeight: "400px",
                    maxWidth: "315px",
                  }}
                  onClick={() =>
                    window.open(getRecommendBanner[2]?.redirect_url, "_blank")
                  }
                >
                  <ZoomInDiv className="d-flex align-items-center cursor-pointer flex-column justify-content-center couponCard h-100">
                    <a href={getRecommendBanner[2]?.redirect_url}>
                      <img
                        style={{
                          objectFit: "contain",
                          maxWidth: "280px",
                        }}
                        src={`${BASE_URL}/${getRecommendBanner[2].image_upload}`}
                        alt="Banner 2"
                      />
                    </a>
                  </ZoomInDiv>
                </div>
              ) : (
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <Coupon
                    couponData={data}
                    setSelectedCoupon={setSelectedCoupon}
                    selectedCoupon={selectedCoupon}
                  />
                </div>
              )
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default memo(DealSectionOne);
