import React, {useState} from 'react';
import Footer from 'rc-footer';
import {useSelector, useDispatch} from "react-redux";
import 'rc-footer/assets/index.css';
import { getAllCategoryAction, getFooterAction } from "../../../redux/actions";
import Macro from "../../../utils/ReplaceMacro";
import { getImageUrl } from "../../../utils";

export default function FooterComponent() {

const dispatch = useDispatch();

    const {
    Category: { categoryList },
    Stores: { storesList },
  } = useSelector((state) => state);
  const { domainData, loading: loadingWebsiteData } = useSelector(
    (state) => state.Website
  );
  const [categories, setCategories] = React.useState([]);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  React.useEffect(() => {
    function fetch() {
      dispatch(getAllCategoryAction());
      dispatch(getFooterAction());
    }
    fetch();
  }, [dispatch]);
  React.useEffect(()=> {

      let temp = categories;
    categoryList.slice(0, 5).map((o)=>{
      temp.push({title: o.category});

    })
    setCategories(temp);

  }, [categoryList])

  return (
    <div style={{marginTop:"2%"}}>
    <Footer
     style={{ background:"rgb(184, 9, 121)", color:"#fff"}}
      columns={[
        
        {
          icon: (
            <i className="fa fa-list" style={{color:"white"}}></i>
          ),
          title: "Categories",
          url:`${window.location.protocol}//${window.location.hostname}/category`,
          description: '知识创作与分享工具',
          openExternal: true,
          items: [ {
            title: "Professional",
            url:`${window.location.protocol}//${window.location.hostname}/category/professional`,
            openExternal: true,
          },
          {
            title: "Pets",
            url:`${window.location.protocol}//${window.location.hostname}/category/pets`,
            openExternal: true,
          },
          {
            title: "Air",
            url:`${window.location.protocol}//${window.location.hostname}/category/air`,
            openExternal: true,
          },
          {
            title: "Nutritional Supplements",
            url:`${window.location.protocol}//${window.location.hostname}/category/nutritional supplements`,
            openExternal: true,
          }
          ]   
        },

        {
          icon: (
            <i className="fa fa-envelope" style={{color:"white"}}></i>
          ),
          title: "Contact us",
          url: 'https://yuque.com',
          description: '知识创作与分享工具',
          openExternal: true,
          items: [ {
            title: "Address",
            description: domainData?.contact?.address,
          },
          {
            title: "Phone",
            description: domainData?.contact?.phone

          },
          {
            title: "Email",
            description: domainData?.contact?.email
          },
          ]
        },
        {
          icon: (
            <i className="fa fa-link" style={{color:"white"}}></i>
          ),
          title: "Links",
          url: 'https://yuque.com',
          description: '知识创作与分享工具',
          openExternal: true,
          items: [ {
            title: "Home",
            url:`${window.location.protocol}//${window.location.hostname}/`,
          },
          {
            title: "Categories",
            url:`${window.location.protocol}//${window.location.hostname}/categories`,
            openExternal: true,
          },
          {
            title: "Stores",
            url:`${window.location.protocol}//${window.location.hostname}/store`,
            openExternal: true,
          },
          {
            title: "About us",
            url:`${window.location.protocol}//${window.location.hostname}/aboutUs`,
            openExternal: true,
          }
          ]
        },
      ]}
      bottom={
          <div>  <img
                src={getImageUrl(domainData?.logo)}
                alt=""
                style={{
                  height:"35px",
                  width:"70px",
                  marginRight:"10px"
                }}
              />
              <Macro input={domainData?.footer_text} />

      <button
        className="btn btn-success"
        title="Scroll to top"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          backgroundColor: "rgb(184, 9, 121)",
          borderColor: "white",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1.5rem",
          borderRadius:"50%",
          padding: "6px 8px 0 8px"
        }}
        onClick={() => {
        window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
      }}
      >
        <i className="ti-arrow-circle-up"></i>
      </button>
              </div>
            }
    />
    </div>
  );
}
