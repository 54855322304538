import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from 'react-router-dom';
import { getImageUrl } from "../../../utils";
import { getNewlyAddedStores } from "../../../redux/stores/actions";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.png"

export default function NewlyAddedStores() {
    const { newlyAddedStores } = useSelector((state) => state.Stores);
const dispatch = useDispatch();
  React.useEffect(()=>{
    dispatch(getNewlyAddedStores());
  }, [dispatch])

  return(

    <section id="page" class="container" style={{padding:"1% 0", margin:"1% 0"}}>
   <div>
        
<div  class="thirteen" id="newly-head">
  <h1 style={{background:"#eeee10"}}><i style={{marginRight:"2px"}} class="fa fa-folder"></i> Newly added stores</h1>
</div>
          <div className="grid-container grid-container3" id="grid-container">
                {newlyAddedStores && newlyAddedStores.slice(0, 11).map((data, key) => (
                  <div key={key}>
                     <div className="featured-stores"  style={{backgroundColor:"white", boxShadow: "-2px 1px 0px rgb(196 181 253)", borderRadius: "10px", marginRight:"5px"}}>
                      <Link to={`/store/${data.slug}`}>
                        <img src={getImageUrl(data?.image,PlaceHolderImg)}
                          style={{height: "100%",width: "100%",objectFit: "contain",}}
                          alt=''
                        />
                      </Link>
                    </div>
                  </div>
                ))}
                <div key={7}>
                    <div className="featured-stores" style={{backgroundColor:"white", boxShadow: "-2px 1px 0px rgb(196 181 253)", borderRadius: "10px", textAlign:"center", paddingTop:"20%"}}>
                      <Link to={`/newlyaddedstores`} style={{ color: "purple", fontWeight:"bold", verticalAlign:"center"}}>
                        
                        Go to new stores
                      </Link>
                    </div>
                  </div>
              </div>
              </div>
              </section>
              )
            }