import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from 'react-router-dom';
import { getImageUrl } from "../../../utils";
import { getNewlyAddedStores } from "../../../redux/stores/actions";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "nuka-carousel";

const NewlyAddedStores = () => {

const dispatch = useDispatch();

const { newlyAddedStores } = useSelector((state) => state.Stores);



  React.useEffect(()=>{
    dispatch(getNewlyAddedStores());
  }, [dispatch])

  return(

    <section  id="page" class="container">
        
<div style={{marginBottom:"2%"}}>
  <h1 className="h4-section-header" style={{maxWidth:"13rem"}}>NEWLY ADDED STORES &nbsp;<i className="fa fa-film"></i></h1>
</div>
<div className="newly-banner-h4">
<Carousel>
<div className="carousel-stores-h4-grid-new">
  { newlyAddedStores &&
            newlyAddedStores.length > 0 &&
            newlyAddedStores.slice(0, 12).map((data, i)=>{
      return(
       <div class="card" style={{borderRadius:"0", border:"1px dotted #EF4444"}}>
    <div class="card-header" style={{height:"100px", padding:"5px"}}>
       <Link to={`/store/${data?.slug}`}>
                        <img src={getImageUrl(data?.image,PlaceHolderImg)}
                          style={{height: "100%",width: "100%",objectFit: "contain",}}
                          alt=''
                        />
                      </Link>
    </div>
    <div class="card-body">    
      <strong style={{color:"#7F1D1D", fontSize:"12px"}}>
        {data?.slug.toUpperCase()}
      </strong>
    </div>
     
       </div>
        )
    })}
</div>
<div className="carousel-stores-h4-grid-new">
  { newlyAddedStores &&
            newlyAddedStores.length > 0 &&
            newlyAddedStores.slice(12, 24).map((data, i)=>{
      return(
       <div class="card" style={{borderRadius:"1px", border:"1px dotted #EF4444"}}>
    <div class="card-header" style={{height:"100px", padding:"5px"}}>
       <Link to={`/store/${data?.slug}`}>
                        <img src={getImageUrl(data?.image,PlaceHolderImg)}
                          style={{height: "100%",width: "100%",objectFit: "contain",}}
                          alt=''
                        />
                      </Link>
    </div>
    <div class="card-body">    
      <strong style={{color:"#7F1D1D", fontSize:"12px"}}>
        {data?.slug.toUpperCase()}
      </strong>
    </div>
     
       </div>
        )
    })}
</div>
      </Carousel>
     
     </div>
              </section>
              )
            }

  export default memo(NewlyAddedStores);